/*
 * NAVIGATION STYLES
 *
 * This file contains all styles for the navigation.
 *
 */

/*
 * Body CSS for fixed navigation bar. Note this is dependent on the complete height of the navigation bar.
 */
body {
  padding-top: 89px;
}
.navbarOfa {
  background-color: @bg-brandWhite--navbarOfa;
  border-color: @borderColor-brandNeutral--navbarOfa;
}
.navbar--bottom {}
.navbarOfa-navbarItem {
  color: @color-brandBlack--navbarItem;
  font-size: @fontSize-base--navbarItem;
  text-decoration: none;
}
.navbarOfa-navbarItem .ofa-icon {
  font-size: 24px;
}
.nav > li > a.navbarOfa-navbarItem {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 18px;
  padding-top: 18px;
}
.dropdown-menu {
  width: 940px;
  border: 0;
  border-top: 1px solid @color-brandNeutral;
  top: 60px;
  padding: 30px 5px 30px 40px;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
}
.exportUser .dropdown-menu {
  width: 1080px;
}
.dropdown-menu>li>a {
  color: @color-brandNeutral;
  font-size: @fontSize-large;
  font-family: @fontFamily-bold;
}
.dropdown-menu>li>a:hover {
  background-color: inherit;
  color: @color-brandNeutral;
}
.menu-category + ul >li {
  padding: 5px 0;
}
.navbar-left {
  padding-top: 7px;
  padding-bottom: 7px;
  color: @color-brandBlack--navbarItem;
}
.nav > li > a.navbar--neutral-navbarItem {
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
}
.nav > li.active > a.navbarOfa-navbarItem {
  border-bottom: 3px solid @color-brandDark;
  padding-bottom: 16px;
}
.nav>li>a:hover.navbarOfa-navbarItem:hover,
.nav>li>a.navbarOfa-navbarItem:focus{
  color: @color-brandBlack--navbarItem;
  background-color: @bg-brandLight--navbarItem;
}
.navbar--neutral {
  background-color: @bg-brandLight--navbar--light;
  border-bottom:  1px solid @color-brandNeutral;
}
.navbar--neutral-navbarItem {
  color: @color-brandBlack--navbarItem;
  font-size: @fontSize-small--navbarItem;
  text-decoration: none;
}
.nav>li>a:hover.navbar--neutral-navbarItem:hover,
.nav>li>a.navbar--neutral-navbarItem:focus {
  background-color: @bg-brandNeutral--navbarItem;
  color: @color-brandWhite--navbarItem;
}
.navbarOfa-brand > img {
  height: 53px;
}
.navbar--bottom .navbar-nav {
  height: 53px;
}

.navbar-shop--upper li:not(:first-child):before {
  content: "|";
  position: absolute;
  margin-top: 5px;
  margin-left: -3px;
}

.link-banner {
  width: 120px;
  position: relative;
  top: -1px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  text-align: center;
  margin-left: 10px;
}
.link-banner > a {
  background-color: @color-bluePortal;
  padding: 15px 0 16px 0 !important;
  overflow: hidden;
  line-height: 20px;
  color: @color-brandWhite;
}
.link-banner > a:hover {
  background-color: @color-blueDark !important;
  color: @color-brandWhite !important;
}
.link-banner::before {
  content: " ";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent @color-blueDarkest transparent transparent;
}
.link-banner a > div:first-of-type{
  width: 88%;
  float: left;
}
.link-banner a > div:last-of-type span {
  position: relative;
  top: 10px;
  right: 14px;
}

@media (max-width: 1024px) {
  .navbar-left {
    display: none;
  }
}
@media (min-width: 1300px) {
  .navbarOfa-navbarItem {
    font-size:1.2em;
  }
  .navbar--neutral-navbarItem {
    font-size: @fontSize-base--navbarItem;
  }
  .link-banner {
    width: 145px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
.navbar-nav>li>a {
  padding-bottom: 12px;
}
}

@media (max-width: 1180px) {
  .nav > li > a.navbar--neutral-navbarItem {
    padding-left: 13px;
    padding-right: 13px;
  }
  .navbar-nav>li>a {
    padding-bottom: 7px;
  }
  .navbarOfa-navbarItem > span {
    display: block;
    text-align: center;
  }
  .navbarOfa-navbarItem > .navbarOfa-cartQuantity {
    display: inline;
  }
  .navbarOfa-brand > img {
    margin-top: 7px;
  }
  .dropdown-menu {
    top: 67px;
  }
  //.link-banner
  .navbar--bottom .navbar-nav {
    height: 67px;
  }
  .link-banner {
    width: 125px;
    margin-left: 15px;
  }
  .link-banner > a {
    line-height: 24px !important;
  }
  .nav > li > a.navbarOfa-navbarItem {
    padding-bottom: 11px;
    padding-top: 12px;
  }
  .nav > li.active > a.navbarOfa-navbarItem {
  padding-bottom: 8px;
}
}

@media (min-width: 1181px) {
  .navbarOfa-brand > img {
    height: 60px;
  }
  //.navbar--bottom .navbar-nav {
  //  height: 53px;
  //}
}

// mobile nav styles

.mobile-navbar {
  display: none;
}
.mobile-navbar-collapse {
  display: none;
}
@media @media--tablet {
body {
  padding-top: 0 !important;
}
  .hoverSearch {
    display: block;
    top: 4.3em;
    z-index: 15;
    width: 100vw;
    right: 0;
  }
  .navbar:not(.mobile-navbar) {
    display: none;
  }
  .mobile-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    .hamburger {
      background: none;
      border: none;
    }
  }
  .mobile-navbar-collapse {
    .nav-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      list-style-type: none;
      padding-left: 0;
      font-size: 16px;
      li:last-child {
        border-bottom: 1px solid @color-brandNeutral;
      }
      li {
        border-top: 1px solid @color-brandNeutral;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 1.5em;
        a {
          padding-top: 1.5em;
          padding-bottom: 1.5em;
          padding-left: 1em;
          padding-right: 1em;
          display: inline-block;
        }
        ul {
          padding-left: 0;
          list-style-type: none;
        }
      }
      li[aria-expanded="true"] {
        svg {
          transform: rotate(0deg);
          transition: .3s ease-in-out;
        }
      }
      li.js-navigationShop {
        background-color: @color-bluePortal;
        a {
          color: @color-brandWhite;
        }
      }
      .js-cart.cart {
        display: flex !important;
      }
    }
    .nav-list {
      li {
        svg {
          transition: .3s ease-in-out;
          transform: rotate(-90deg);
        }
      }
    }
  }
  .mobile-nav-children {
    li {
      display: block !important;
      padding-right: 0 !important;
      a {
        padding-left: 2em !important;
      }
    }
  }
  .mobile-navbar-collapse.active {
    display: block;
  }
  .mobile-nav-children {
    padding: 0em;
  }
}

.hint--superuser {
  position: fixed;
  top: 120px;
  right: 20px;
  background: #ed143d;
  z-index: 10000;
  padding: 10px;
  color: white;
}
