.select2-search { display: none; }
.selection:focus,
.select2-container:focus,
.select2-selection:focus { outline: 0; }
.select2-dropdown,
.select2-container--default .select2-selection--single {
  .border-radius(0);
  border-width: 1px;
  border-style: solid;
}
.select2-container .select2-selection--single {
  height: 26px;
  margin-top: -2px;
}
.select2-container--default .select2-selection--single {
  border-color: @color-brandNeutral;
}
.select2-container--focus .select2-selection--single {
  border-color: @color-blueNeutral;
  border-width: 2px;
}
.select2-dropdown,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-color: @color-blueNeutral;
  border-width: 2px;
  z-index: 20000;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  height: 26px;
  width: 28px;
  background-image: url(../../images/input-sprites.png);
  background-position: -5px -156px;
  position: relative;
  display: block;
  top: 0;
  right: 0;
  left: auto;
  margin: 0;
}
.select2-container--focus .select2-selection--single .select2-selection__arrow b {
  background-position: -5px -126px;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single b {
  background-position: -5px -126px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 28px;
  top: -2px;
  right: 0;
}
.select2-results {
  .box-shadow(0; 5px; 20px; 2px; #bbb);
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: @color-brandLighter;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: @color-brandLight;
  color: @color-brandBlack--selectInput;
}
.select2-results__option[aria-selected]:hover {
  background-color: @bg-brandLight--selectInput;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: @color-brandBlack--selectInput;
  line-height: 25px;
  padding-right: 25px;
  padding-left: 8px;
}
.select2-container--focus .select2-selection--single .select2-selection__rendered {
  line-height: 23px;
  padding-left: 7px;
}
.select2-container.is-faulty {
  border: 1px solid @color-brandWarning;
}
/* Different widths for dropdowns */
.u-w80 > .select2-container {
  width: 80px !important;
}
.u-w90 > .select2-container {
  width: 90px !important;
}
.u-w100 > .select2-container {
  width: 100px !important;
}
.u-w130 > .select2-container {
  width: 130px !important;
}
.u-w150 > .select2-container {
  width: 150px !important;
}
.u-w200 > .select2-container {
  width: 200px !important;
}
.u-w250 > .select2-container {
  width: 250px !important;
}
.u-w300 > .select2-container {
  width: 300px !important;
}
.u-w380 > .select2-container {
  width: 380px !important;
}
.u-w400 > .select2-container {
  width: 400px !important;
}
/* Widths that adapt according to screen size */
.u-w350A > .select2-container { width: 200px !important; }
@media (min-width: 768px) {
  .u-w350A > .select2-container {
    width: 300px !important;
  }
}
@media (min-width: 992px) {
  .u-w350A > .select2-container {
    width: 350px !important;
  }
}

.u-w100P > .select2-container {
  max-width: 100% !important;
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--focus .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered {
  margin: 0;
}