/*
 * Horizontal lines
 */

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid @color-brandBlack--hr;
  margin-left: 0;
}
.hr--brandLight {
  border-top: 1px solid @color-brandLight;
}
.hr--brandNeutral {
  border-top: 1px solid @color-brandNeutral;
}
.hr--login {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid @color-brandNeutral;
}
.hr-w300 {
  max-width: 300px;
  width: 100%;
}
.hr-w400 {
  width: 400px;
}
.hr-w450 {
  width: 100%;
  max-width: 450px;
}