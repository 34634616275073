// this is some custom styling for the CMS-pages (located under the website (portal) app)
body .main-container > .infosite {
  font-size: @fontSize-large;
}
.infosite a:not([class*="btn"]) {
  color: @color-blueNeutral;
  text-decoration: none;
}
.infosite a:hover:not([class*="btn"]) {
  color: @color-blueNeutral;
}
.infosite a.redHover:hover {
  color: @color-lastofa;
}
.infosite ul:not([class]) > li,
.infosite .cms-plugin > li {
  list-style: none;
}
.infosite ul:not([class]) > li:before,
.infosite .cms-plugin > li:before {
  content: "\2013\a0";
  margin-left: -17px;
}