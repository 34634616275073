.shipping-addresses-wrapper-mobile {
    display: none;
}

@media @media--tablet {
    .shipping-addresses-wrapper, .shipping-addresses-hint-desktop {
        max-width: 90%;
        margin: auto;
    }
}
@media @media--mobile {
    .shipping-addresses-wrapper {
        display: none;
    }
    .shipping-addresses-hint-desktop {
        display: none;
    }
    .shipping-addresses-wrapper-mobile {
        display: block;
        max-width: 95%;
        margin: auto;
        .shipping-addresses-hint {
            display: block;
            margin-bottom: .5em;
            .btn.btn--primary {
                width: 100%;
                text-align: center;
            }
            .hint {
                position: relative;
                transform: none;
                text-align: center;
            }
        }
        .shippingAddress {
            padding: 1em 1em 0 1em;
            border-top: 1px solid #000;
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: .5em;
            span:nth-child(odd) {
                font-family: "Humanist Condensed Bold";
            }
            div:last-child {
                border-top: 1px solid @color-brandLight;
                display: flex;
                justify-content: space-around;
                grid-column: 1 / span 2;
                padding-bottom: .5em;
                padding-top: .5em;
            }
        }
        .shippingAddress:nth-child(odd) {
            background-color: @color-brandLighter;
        }
        .shippingAddress:last-child {
            border-bottom: 1px solid #000;
        }
    }
}