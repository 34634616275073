/*
 * FONT FACES
 *
 * All fonts are defined in this file. Never define a new font anywhere else in the project.
 * Always use TTF, WOFF, EOT and SVG files to ensure cross browser compatibility. 
 */

@font-face {
    font-family: 'Humanist Condensed Light';
    src: url("../fonts/humanist-condensed-light.eot"); /* IE9 */
    src: url('../fonts/humanist-condensed-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/humanist-condensed-light.woff') format('woff'),
         url('../fonts/humanist-condensed-light.ttf') format('truetype'),
         url("../fonts/humanist-condensed-light.svg") format("svg");
}

@font-face {
    font-family: "Humanist Condensed Bold";
    src: url("../fonts/humanist-condensed-bold.eot"); /* IE9 */
    src: url('../fonts/humanist-condensed-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/humanist-condensed-bold.woff') format('woff'), 
         url('../fonts/humanist-condensed-bold.ttf') format('truetype'),
         url("../fonts/humanist-condensed-bold.svg") format("svg");
}

@font-face {
    font-family: "Humanist Condensed Italic";
    src: url("../fonts/humanist-condensed-italic.eot"); /* IE9 */
    src: url('../fonts/humanist-condensed-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/humanist-condensed-italic.woff') format('woff'), 
         url('../fonts/humanist-condensed-italic.ttf') format('truetype'),
         url("../fonts/humanist-condensed-italic.svg") format("svg");
}

@font-face {
  font-family: 'AGaramondPro-Italic';
  src: url('../fonts/AGaramondPro-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/AGaramondPro-Italic.otf')  format('opentype'),
	     url('../fonts/AGaramondPro-Italic.woff') format('woff'), url('../fonts/AGaramondPro-Italic.ttf')  format('truetype'), url('../fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ofa-portal';
  src:  url('../fonts/ofa-portal.eot?gri14h');
  src:  url('../fonts/ofa-portal.eot?gri14h#iefix') format('embedded-opentype'),
    url('../fonts/ofa-portal.ttf?gri14h') format('truetype'),
    url('../fonts/ofa-portal.woff?gri14h') format('woff'),
    url('../fonts/ofa-portal.svg?gri14h#ofa-portal') format('svg');
  font-weight: normal;
  font-style: normal;
}
