.countdownContainer {
  margin-top: 40px;
}
.countdown {
  .box-shadow(0; 0; 20px; 2px; #bbb);
  padding: 10px 15px;
  display: inline-block;
  text-align: center;
  width: auto;
  margin-right: 15px;
  color: @color-brandNeutral;
  font-size: @fontSize-largest;
  background-color: rgba(255, 255, 255, 0.9);
}
.countdown-label {
  font-family: @fontFamily-light;
  line-height: 10px;
  font-size: 1.1em;
}
.countdown-value {
  font-size: 2.1em;
  font-family: @fontFamily-bold;
  line-height: 1em;
}
.topBar {
  background-color: white;
  .box-shadow(0; 0; 20px; 2px; #bbb);
}
.topBar-logo {
  height: 60px;
}
.splashFooter {
  position: absolute;
  bottom: 0;
  margin-right: -10px;
  margin-left: -10px;
  width: 100%;
  height: 60px;
  background-color: @color-brandLighter;
  color: @color-brandDark;
  line-height: 60px;
}