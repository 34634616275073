.gallerySlider {
  max-height:400px;
}
.gallerySlider-slide {
  text-align: center;
}
.gallerySlider-slide img {
  width: auto;
  height: auto;
  max-height: 400px;
  display: inline-block;
  max-width: 100%;
}
.gallerySlider--nav-slide {
  height: 100px !important;
  text-align: center;
}
.gallerySlider--nav {
 height: 100px;
  margin-top: 30px;
}
.gallerySlider--nav img {
  margin: auto 2%;
  cursor: pointer;
  height:auto;
  max-height: 100px;
  display: inline-block;
  max-width: 100%;
}
.gallerySlider--nav img:focus {
  outline:0;
}
.slick-list,
.slick-track {
  height: 100%;
}
.slick-arrow {
  position: absolute;
  top: 35px;
  background-color: transparent;
  border: 0;
  z-index: 10;
}
.slick-arrow:focus {
  outline: 0;
}
.slick-next {
  right: 0;
}
.slick-slide {
  width: 100%;
  height: auto;
}
@media print {
  .gallerySlider {
    display: none;
  }
}
@media (max-width: 1040px) {
  .gallerySlider--nav {
    margin-top: 10px;
  }
}