.measurementColumn-header {
  min-height: 65px;
}
.measurementSvgContainer {
  text-align: center;
}
.measurementSvgContainer svg {
  max-width: 100%;
  height:400px;
}
.measurementResult--description {
  width: 250px;
  text-align: center;
  margin: 0 auto;
}
.measurementResult-backButton {
  position: absolute;
  left: 0;
}
/* This is for the patient detail measurement view */
.measurement-headline {
  border-bottom: 1px solid @color-brandBlack;
  font-family: @fontFamily-bold;
  font-size: @fontSize-large;
  padding-bottom: 5px;
  margin-bottom: 3px;
 }
.measurement-subHeadline {
  margin-top: 3px;
  border-bottom: 1px solid @color-brandBlack;
  font-family: @fontFamily-bold;
  font-size: @fontSize-base;
  padding-bottom: 3px;
  margin-bottom: 3px;
}
.measurement-letter {
  font-family:@fontFamily-measurement;
  margin-right: 0.1em;
}
.measurementImage svg {
  max-height: 400px;
  max-width:100%;
}
.u-measurementGrow {
  margin-top: 310px;
}
.u-measurement-borderRight { border-right: 1px solid @color-brandBlack; }
@media (max-width: 1040px) {
  .u-measurementGrow {
    margin-top: 0;
  }
  .u-measurement-borderRight { border: 0; }
}
.toleranceRange {
  color: @color-brandDark;
}
.preventClickOverlayColored {
  .preventClickOverlay;
  background-color: white;
  opacity: 0.7;
}
@media (max-width: 1040px) {
  .measurementSvgContainer svg {
    max-width: 100%;
    height:350px;
  }
}

.mobile-measurements {
  display: none;
}
.desktop-patient-measurement {
  display: block;
}
.mobile-patient-measurement {
  display: none;
}
@media @media--tablet {
  .desktop-patient-measurement {
    display: none;
  }
  .mobile-patient-measurement {
    display: block;
  }
  .dropdown-arrow path {
    display: block !important;
  }
  .panel-heading a[aria-expanded="true"] .dropdown-arrow {
    transform: rotate(90deg);
  }
  .js-measurementFormModalBody {
    display: none;
  }
  .mobile-measurements.js-measurementFormModalBody {
    display: block;
  }
  .mobile-measurements input {
    scroll-margin: 100px;
  }
  .row.bottom-grid::before {
    content: "";
    display: none;
  }
  .mobile-measurements, .mobile-patient-measurement {
    .bottom-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: .5em;
    }
    .measurementInput-input {
      width: 50%;
    }
    .panel-heading a {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
  }
  .desktop-tolerances {
    .measurementSvgContainer {
      display: none;
    }
    .row-eq-height {
      justify-content: space-between;
      .col-md-4 {
        width: 50%;
      }
    }
    .left-leg-row {
      display: flex;
      flex-direction: column-reverse;
    }
    .u-visibilityHidden {
      display: none;
    }
  }
  .tolerances-submit {
    .pull-right {
      float: none !important;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      row-gap: 1em;
      text-align: center;
      max-width: 100%;
      form {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: 1;
        label {
          width: 95.5%;
          margin: 0 10px;
        }
      }
      .btn--primary {
        grid-row: 2;
        grid-column: 1;
      }
      .btn--primaryBlue {
        grid-row: 2;
        grid-column: 2;
      }
    }
  }
  .panel-collapse .btn--link {
    width: 100%;
    text-align: center;
  }
  .length-tolerance {
    margin-top: 2em;
  }
  #left_foot .measurementSvgContainer svg, #right_foot .measurementSvgContainer svg {
    clip-path: polygon(0 30%, 100% 30%, 100% 70%, 0% 70%);
    margin-top: -6em;
    margin-bottom: -9em;
  }
}

@media @media--mobile {
  .js-modal-measurement {
    .modal-lg {
      width: 100vw;
      max-width: 100vw;
      height: auto;
      .modal-content {
        height: 100%;
      }
    }
  }
  .desktop-tolerances {
    .table-data--slim {
      padding: 0;
    }
  }
  .js-measurementModalContent {
    padding-left: 1em;
    padding-right: 1em;
  }
  .bottom-grid {
    grid-template-rows: 1fr;
  }
  .tolerances-submit .pull-right {
    grid-template-rows: 3fr;
    grid-template-columns: 1fr;
    form {
      grid-column-start: 1;
      grid-column-end: 1;
    }
    .btn--primary {
      grid-row: 3;
      grid-column: 1;
    }
    .btn--primaryBlue {
      grid-row: 2;
      grid-column: 1;
    }
  }
  .measurementResult-backButton {
    bottom: 9.5em;
  }
  .measurement-results-wrapper .measurementResult-backButton {
    bottom: 0;
  }
  .measurementSvgContainer svg {
    height: 280px;
  }
}