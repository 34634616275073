.flatknittingTable {

}
.flatknittingTable-head {
  text-align: center;
}
.flatknittingTable-data {

}
.flatknittingTable-data:first-child {

}
.flatknittingTable-data input {
  width: 50px;
  margin: 5px;
}
.fingerNumber {
  .border-radius(50%);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  border: 1px solid @color-brandBlack;
  margin-right: 10px;
}