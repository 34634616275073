.flat-row {
  width: 100%;
}
.flat-row:first-child {
  margin-top: 50px;
}
.flat-head {
  background-color: @color-lastofa;
  color: white;
  line-height: 50px;
  float: left;
  width: 50%;
  padding-left: 8px;
  font-size: @fontSize-large;
}
.flat-data:first-child {
  border-right: 1px solid @color-lastofa;
  border-left: 1px solid @color-lastofa;
}
.flat-data:not(:first-child) {
  border-right: 1px solid @color-lastofa;
}
.flat-data {
  float: left;
  width: 50%;
  height: 35px;
  text-align: center;
  border-bottom: 1px solid @color-lastofa;
}
.flat-label {
  float: left;
  width: 40%;
  line-height: 35px;
  border-right: 1px solid @color-lastofa;
}
.flat-label-print {
  .flat-label;
  width: 35%;
}
.flat-input {
  float: left;
  width: 60%;
  input, .printInput {
    height: 34px;
    width: 100%;
    border: 0;
    &.is-faulty{
      border: 1px solid @color-lastofa;
      color: @color-lastofa;
      font-weight: bold;
    }
  }
  .printInput {
    line-height: 34px;
  }
}
.flat-data--noBorder {
  border: 0 !important;
}
.flat-data--borderLeft {
  border:0 !important;
  border-left: 1px solid @color-lastofa !important;
}
.flat-data--borderBottom {
  border:0 !important;
  border-bottom: 1px solid @color-lastofa !important;
}
.flat-data--borderBottomRight {
  border:0 !important;
  border-bottom: 1px solid @color-lastofa !important;
  border-right: 1px solid @color-lastofa !important;
}
.flat-data--borderRight {
  border:0 !important;
  border-right: 1px solid @color-lastofa !important;
}
.flatknitting-imageInput input {
  background-color: transparent;
  border-color: transparent;
}
.flatknitting-imageInput input#productionNumber {
  border-color: @color-brandNeutral;
}
.flatknitting-imageInput input:focus {
  background-color: transparent;
  border-color: transparent;
}
.flatknittingForm .inputGroup-radioLabel {
  margin-bottom: 10px;
}
.flatknittingFootTable th {
  background-color: @color-lastofa;
  color: @color-brandWhite;
  padding: 3px 3px;
}
.flatknittingFootTable td {
  border: 1px solid @color-lastofa;
  height: 30px;
  padding: 0 3px;
}
.flatknittingFootTable input {
  margin: 0;
  width: 70px;
  height: 30px;
  border: 0;
  &.is-faulty{
    border: 1px solid @color-lastofa;
    color: @color-lastofa;
    font-weight: bold;
  }
}

.flatknitting-measures {
  position: relative;
}
.vue-tooltip-theme {
 opacity: 1;
}

.measurement-btn-row {
  display: flex;
  justify-content: space-between;
}

.flatknitting-measurement-modal .modal-dialog {
  width: fit-content; 
  width: -moz-fit-content;
}

@media only screen and (max-width: 991px) {
  .scrollmagic-pin-spacer {
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 975px) {
  #measurement-modal {
    overflow-x: scroll;
  }
  .flatknitting-measures img {
    display: none;
  }
}