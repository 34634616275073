.frontpage {
  //padding: 0 20px;
}
.frontpageHeader-fullWidth {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: -10px; // So the image sits below the navbar
  position: relative;
}
.frontpage-tile {
  padding-top: 20px;
  padding-bottom: 20px;
}
.frontpage-tile--heading {
  .inputGroup-headline;
  & + .dataTables_wrapper {
    margin-bottom: 15px;
  }
}
.frontpage-shadowBox {
  .shadowBox;
  position: relative;
  width: 100%;
  padding: 15px 30px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
.frontpage-tile--description {
  word-wrap: break-word;
  margin-top: 20px;
}
.frontpageHeader-container {
  margin: 0; // Fix overflowing
  .u-translate(0, 8%);
  margin-bottom: 95px;
}
.frontpage-imageContainer {
  margin-left: -30px;
  margin-right: -30px;
  height: 210px;
  text-align: center;
}
.frontpage-tile--image {
  max-height: 215px;
  width: auto;
  max-width: 100%;
}
.frontpage-headline {
  height: 75px;
}
.frontpage-shadowBox-news{ border-bottom: 7px solid; } // Color is dynamically set in the news_tile template

/* Static tiles */
.frontpage-imageTile--image {
  background-image: url('../../images/fachkunden.jpg');
  height: calc(100% + 20px);
  display: inline-block;
  width: 55%;
  margin-left: -30px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-size: cover;
}

/* This is just for the font color of the frontpage header content. We have a button here, just out of convenience, */
/* so that we can easily edit the color at one location */
.btn--primaryFrontpageHeader {
  border-color: @color-brandLight;
  color: @color-brandLight;
}
.btn--primaryFrontpageHeader:hover {
  border-color: @color-brandDark;
  color: @color-brandDark;
}
.btn--primaryFrontpageHeader[disabled]:hover {
  background-color: @color-brandLight;
  border-color: @color-brandLight;
}
.frontpageHeader-text {
  color: @color-brandLight;
}
.frontpage-description {
  margin-bottom: 15px;
}
.frontpage .brandFilter {
  display: flex;
  .filter-brand--item {
    flex-basis: 26%;
  }
}
@media (min-width: 768px) {
  .frontpage-tile {
    padding: 20px;
  }
  .frontpage-shadowBox {
    padding: 10px 30px;
  }
  .frontpage-description {
    margin-bottom: 0;
  }
  .frontpage-tile--heading {
    & + .dataTables_wrapper {
      margin-bottom: 30px;
    }
  }
  .frontpage-tile--footer {
    position: absolute;
    bottom: 10px;
  }
  .frontpage-upperBox {
    height: 265px;
  }
  .frontpageHeader-container {
    margin-bottom: 50px;
  }
  .frontpage .brandFilter {
    .filter-brand--item {
      flex-basis: 18%;
    }
  }
}
@media (min-width: 992px) {
  .frontpage .brandFilter {
    .filter-brand--item {
      flex-basis: 13%;
    }
  }
}
@media @media--tablet {
  .frontpageHeader-fullWidth {
    top: 0;
  }
}