/*
 * keyValue is a component which displays information in form of a key, value pair.
 */

.keyValue {}
.keyValue--sub {
  margin-top: -30px;
}
.keyValue-key {
  margin: 15px 0;
  font-family: @fontFamily-bold--keyValue-key;
  color: @color-brandBlack;
  width: 200px; /* IMPORTANT there is a dependency to formField-label in the layout!!! */
  display: inline-block;
  vertical-align: top;
}
.keyValue-value {
  margin: 15px 0;
  display: inline-block;
}

@media @media--tablet {
  .keyValue-key {
    max-width: 150px;
  }
}