/*
 * Login Overlay
 */

.loginOverlay {
  position: absolute;
  right: 0;
  width: 35%;
  margin-top: 5%;
  background-color: @color-brandWhite;
}
.inputGroup-textInput--transparent {
  background-color: rgba(255, 255, 255, 0);
}
.loginOverlay-headline {
  color: @color-brandNeutral;
}
.retailerFinder {
  margin-top: 20px;
  margin-bottom: 20px;
}
.retailerFinder-flag {
  background-image: url("../../images/login-redflag.png");
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  width: 166px;
  height: 50px;
  color: @color-white--retailerFinder;
  margin-left: -64px;
  line-height: 45px;
  font-size: 16px /* This font size is not defined in the styleguide */;
  padding-left: 15px;
}
.retailerFinder-flag .ofa-icon {
  font-size: 1.5em;
  line-height: 1em;
}
.loginOverlay-brand {
  /* Align the brand to the top right corner */
  margin-top: -10px;
  margin-right: -30px;
}
.loginOverlay-hint {
  color: @color-brandWhite--loginOverlay-hint;
  background-color: @color-brandNeutral--loginOverlay-hint;
  padding: 20px 40px;
  margin-left: -50px;
  margin-right: -30px;
  margin-bottom: -10px;
}
.retailerFinder-hint {
  float: left;
  width: 45%;
  margin-left: 20px;
  color: @color-brandWarning--retailerFinder-hint;
}
/*
 * Registration Overlay
 */
.registrationOverlay {
  display: flex !important;
  position: absolute;
  right: 0;
  margin-top: 5%;
  width: 50%;
}
.registrationAdvantages {
  display: inline-block;
  vertical-align: top;
  width: 30%;
  //height: 100%;
  background-color: @bg-brandLight--navbarItem !important;
  right: -5px;
  position: relative;
  padding: 80px 40px 40px !important;
}
.registrationOverlay-content {
  width: 70%;
  margin-left: auto;
  padding-left: 50px !important; /* Overwrite the shadowBox padding */
}
.registrationAdvantages-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.registrationAdvantages-list {
  margin-top: 30px;
  //list-style-image: url(''); /* ToDo: Insert green checkmark */
  list-style: None;
}

/* Dash for the bullet list, since Ofa Marketing didn't like the bulletpoint */
.registrationAdvantages-list > li:before {
  content: "-";
  text-indent: -5px;
  margin-right: 10px;
}

/*The indents the second line*/
.registrationAdvantages-list > li {
  text-indent: -14px;
}

.registrationAdvantages-list li {
  margin: 15px 0;
}
/* Lower the margins between the labels and fields */
.registrationOverlay .inputGroup-textInput, .registrationOverlay .inputGroup-textLabel {
  margin: 15px 0;
}
.registrationOverlay .inputGroup--sub {
  margin-top: -10px;
}
.registrationOverlay .inputGroup-errors {
  margin-top: -5px;
}

/*
 Tablet Styles
 */
@media @media--tablet-portrait {
  .loginOverlay {
    width: 70%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    margin: 0;
    .u-centerVerticallyHorizontally;
  }
  .loginOverlay-headline {
    margin-top: 50px;
  }
  .retailerFinder {
    margin-top: 80px;
  }
  .retailerFinder-hint {
    width: 55%;
  }
  .registrationOverlay {
    width: 95%;
  }
}
@media @media--tablet-landscape {
  .loginOverlay {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    margin: 0;
    .u-centerVerticallyHorizontally;
  }
  .loginOverlay-headline {
    margin-top: 60px;
  }
  .loginTabletLeftColumn {
    display: inline-block;
    width: 50%;
    padding: 10px 0 40px 0;
  }
  .loginTabletRightColumn {
    display: inline-block;
    width: 50%;
    float: right;
    padding: 10px 0 40px 0;
  }
  .retailerFinder {
    display: inline-block;
    margin-top: 45px;
  }
  .retailerFinder-flag {
    background-image: url("../../images/login-redflag-right.png");
    display: inline-block;
    position: absolute;
    right: -11px;
  }
  .retailerFinder-hint {
    width: 58%;
    margin-left: 0;
  }
  /* Remove the hr divider since on tablet landscape the forms are in the same row */
  .loginOverlay .hr {
    visibility: hidden;
  }

  .registrationOverlay {
    width: 80%;
  }

}
@media @media--tablet {
  .fullscreenBackground .shadowBox.loginOverlay {
    display: block !important;
  }
}
  @media @media--mobile {
    .mobileLoginWrapper {
      padding-right: 20px;
    }
    .fullscreenBackground .loginOverlay {
      width: 100vw;
      min-height: 100vh;
      margin-top: 0;
      padding-top: 80px;
    }
  }

.passwordCheck .glyphicon-remove {
  color: @color-warning--textInput;
}
.passwordCheck .glyphicon-ok {
  color: @color-brandSuccess;
}

.passwordMismatch {
  -webkit-transition: opacity ease-in-out .2s;
  -moz-transition: opacity ease-in-out .2s;
  -ms-transition: opacity ease-in-out .2s;
  -o-transition: opacity ease-in-out .2s;
  transition: all ease-in-out .2s;
  //opacity: 0;
  margin-left: 200px;
  color: @color-warning--textInput;
}

.loginImprintPrivacy {
  float: right;
}