.js-needsSpec .select2 {
  width: 150px !important;
}

.tradeFairLineInfo-warning {
  border: 1px solid @color-brandWarning;
  color: @color-brandWarning;
}

.mobile-basket-table, .mobile-shipppig-icon, .mobile-basket-link {
  display: none;
}
.mobile-amount-btn {
  background: none;
  border: none;
  padding: 0;
}
.mobile-basket-amount-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  > * {
    margin-right: 5px !important;
  }
  .js-quantity {
    margin-top: 0;
  }
}

@media (max-width: 1220px) {
  .basketActionButtons {
    margin-bottom: 20px;
  }
}
@media (max-width: 1040px) {
  .basketActionButtons {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 320px) {
  .mobile-basket-link {
    left: 79vw;
  }
}
@media only screen and (min-width: 365px) {
  .mobile-basket-link {
    left: 80vw;
  }
}
@media only screen and (min-width: 425px) {
  .mobile-basket-link {
    left: 84vw;
  }
}
@media only screen and (min-width: 500px) {
  .mobile-basket-link {
    left: 89vw;
  }
}

@media @media--tablet {
  .js-cart.cart {
    display: none !important;
  }
  .mobile-basket-link {
    position: fixed;
    top: 70vh;
    z-index: 100001;
    display: block;
    a {
      display: inline-block;
      background: @color-brandWhite;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      border: none;
      padding: 1em;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
      text-align: center;
      svg {
        margin-top: 4px;
      }
    }
    .cartQuantity-mobile {
      position: absolute;
      right: -5px;
      top: -5px;
      color: @color-brandWhite;
      background: @color-blueNeutral;
      padding: 0.2em;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      text-align: center;
    }
    .basketNotification {
      top: 77vh;
      right: 7em;
      padding: 10px 30px;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    }
  }
  .basket-order-another {
    display: none;
  }
  .basketButtons {
    display: none;
  }
  .basket-group-wrapper {
    width: 95%;
    margin: auto;
    margin-top: 1em;
    .inputGroup > *, .inputGroup-textLabel {
      margin-bottom: 0;
      margin-top: .2em;
    }
    .u-warning {
      display: block;
    }
  }
  .basketForm {
    max-width: 90%;
    margin: auto;
  }
  .desktop-basket-wrapper {
    display: none;
  }
  .table-row--basket {
    display: none
  }
  .table-row--basket-mobile {
    display: block;
  }
  .table.dataTable {
    display: none;
  }
  .inputGroup.pull-left {
    float: none !important;
  }
  .js-price-mobile {
    font-size: 18px;
  }
  .mobile-basket-table {
    display: block;
    margin-top: 1em;
    border-top: 1px solid @color-brandLight;
    .mobile-basket-item:nth-child(odd) {
      background-color: @bg-brandLighter--table-data;
    }
    .mobile-basket-item-last {
      border-bottom: 1px solid black;
    }
    .order-another {
      display: block;
      text-align: center;
      font-size: 18px;
      width: 100%;
      padding: .8em 0;

    }
  }
  .mobile-basket-item {
    border-top: 1px solid black;
    padding: 1em;
    display: grid;
    grid-template-columns: .3fr 1fr .5fr .3fr;
    grid-template-areas: 
      "image details1 price delete"
      "image details2 price delete"
      "placeholder details3 expand expand"
      "placeholder extra extra extra";
    grid-column-gap: .5em;
    .mobile-basket-item-image {
      grid-area: image;
      img {
        width: 100%;
      }
    }
    .mobile-basket-title {
      grid-area: details1;
    }
    .mobile-single-price {
      grid-area: details2;
      font-weight: bold;
    }
    .mobile-price {
      grid-area: price;
      text-align: right;
      font-weight: bold;
    }
    .mobile-price::after, .mobile-single-price::after {
      content: "€";
      font-family: 'Arial, sans-serif';
    }
    .mobile-basket-amount {
      grid-area: details3;
      span {
        display: block;
      }
      .mobile-basket-amount-input-wrapper {
        .inputGroup-textInput {
          margin: 0 .2em;
        }
      }
    }
    .mobile-basket-expand {
      grid-area: expand;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
    }
    .mobile-basket-details {
      grid-area: extra;
      border-top: 1px solid @color-brandDark;
      padding-top: .5em;
      max-width: 100%;
      div {
        margin-top: .2em;
        max-width: 100%;
      }
      .productSpec-inputGroup-input {
        width: 75%;
      }
      .inputGroup-basket--city, .select2.select2-container {
       max-width: 100%;
      }
      .inputGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: .2em;
      }
      .additionalInformation {
        position: relative;
        padding: 0 0 .5em 0;
        margin: 0;
      }
    }
  }
  .panel-group:last-of-type {
    margin-bottom: .5em;
      .basketNetPriceDescription {
      border-bottom: 1px solid black;
      padding: 1em 0;
    }
  }
}
@media only screen and (max-width: 620px) {
  .desktop-shipping-icon {
    display: none;
  }
  .mobile-shipping-icon {
    display: inline;
    margin-left: 0;
  }
  .basket-orderNumber {
    margin-right: 0 !important;
    margin-left: 0 !important;
    label {
      margin-bottom: 0;
      display: block;
    }
    input.inputGroup-textInput {
      width: 100%;
    }
  }
  .basket-addressSelect {
    margin-right: 0 !important;
    margin-left: 0 !important;
    .u-inlineBlock {
      display: block;
    }
    label {
      margin-bottom: 0;
      margin-top: 0;
    }
    .select2.select2-container {
      width: 100% !important;
    }
  }
}
@media @media--mobile {
  .basket-top-filters {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    .commonFilter {
      height: unset;
      line-height: unset;
      margin-top: .3em;
    }
  }
  .basketActionButtons {
      margin-top: .9em;
    button {
      width: 100%;
    }
  }
  .mobile-basket-link .basketNotification {
    max-width: 75%;
    img {
      display: none;
    }
    .u-small {
      display: none;
    }
    padding-right: 5px;
  }
}
@media @media--tablet {
  .estimate-tabbed-view {
    width: 100vw;
    > .u-marginTop30 {
      width: 100%;
    }
  }
  .basketForm .tabbed-view-filters {
    display: flex !important;
  }
  .tabbed-view-wrapper.u-marginTop30 {
    margin-top: 0;
    float: none !important;
    input {
      margin-top: 0;
      margin-bottom: .2em;
    }
  }
  .basketForm .tabbed-view-filters {
    display: flex !important;
    flex-wrap: wrap;
  }
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    a {
      flex: 1 0 auto;
      text-align: center;
      height: unset;
      line-height: unset;
      margin: 0.3em 0 0 0;
      width: 48%;

      &:first-child {
        width: 100%;
      }

      &:nth-child(2) {
        margin-right: 5px;
      }

      &:nth-child(3) {
        margin-left: 5px;
      }
    }

    form {
      width: 100%;
    }
  }
}
