.hoverCart {
  position: fixed;
  right: 16%;
  width: 350px;
  background-color: #fff;
  display: none;
}
.cart:hover .hoverCart {
  display: block;
}
.hoverCart--noFHP {
  right: 10%;
}