.basketNotification {
  position: fixed;
  top: 105px;
  right: 16%;
  width: 350px;
  background-color: #fff;
  display: none;
  z-index: 10101;
  font-size: @fontSize-small;
}
.basketNotification-headline {
  margin-top: 10px;
  color: @color-brandNeutral;
}
.basketNotfication-overlayMenu {
  position: fixed;
  top: 38px;
  right: 15.27777%;
  padding-top: 15px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 15px;
  z-index:10000;
  font-size: 1.2em;
  text-decoration: none;
}
.basketNotification-line {
  min-height: 40px;
}
@media (min-width: 1181px) {
  .basketNotification {
    top: 95px;
  }
  //.basketNotfication-overlayMenu {
  //  padding-top: 118px;
  //  padding-left: 14px;
  //  padding-right: 14px;
  //  padding-bottom: 18px;
  //}
}