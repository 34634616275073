/*
 * Breadcrumbs
 */
.breadcrumbs-container {
  background-color: @color-brandLight;
  text-align: center;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 5px;
}
.breadcrumbs {
  background-color: @color-brandWhite;
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.breadcrumb {
  color: @color-brandNeutral;
  background-color: @color-brandLight;
  display: inline-block;
  position: relative;
  padding: 10px;
  padding-left: 60px;
  padding-right: 40px;
  margin-right: 10px;
  margin-bottom: 0;
}
.breadcrumb.is-active {
  background-color: @color-brandNeutral;
  color: @color-brandWhite;
}
.breadcrumb:first-child:before,
.breadcrumb:last-child:after{
  border: 0;
}
.breadcrumb:last-child {
  margin-right: 0;
}
.breadcrumb:before {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid @color-brandWhite;
  position: absolute; left: 0; top: 0;
  z-index: 1;
}
.breadcrumb:after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid @color-brandLight;
  position: absolute; right: -20px; top: 0;
  z-index: 2;
}
.breadcrumb.is-active:after {
  border-left: 20px solid @color-brandNeutral;
}
.breadcrumbsContainer--standard {
  background-color: @color-brandLight;
}
.breadcrumbs--standard {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  color: @color-brandDark;
  font-size: @fontSize-small;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.breadcrumbs-breadcrumb--standard {
  display: inline-block;
  margin-right: 10px;
}
.breadcrumbs-breadcrumb--standard:not(:last-child):after {
  content: '>';
  display: inline-block;
  margin-left: 10px;
}
.breadcrumbs-breadcrumb--standard.is-active {
  font-family: @fontFamily-bold;
}
@media (max-width: @screen-lg-min) {
  .breadcrumb {
    padding-left: 35px;
    padding-right: 15px;
    margin-right: 4px;
  }
}

.breadcrumbs-container--mobile {
    display: none;
}

@media @media--tablet {
  .breadcrumbs-wrapper {
    padding: 0;
  }
  .breadcrumbs-container {
    display: none;
  }
  .breadcrumbs-container--mobile {
    display: block;
    .breadcrumbs {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .breadcrumb {
        height: 40px;
        min-width: 5%;
        max-width: 10%;
        width: 500px;
        span {
          display: none;
        }
      }
      .breadcrumb.is-active {
        width: 500px;
        min-width: 20%;
        max-width: 70%;
        white-space: nowrap;
        span {
          display: inline;
        }
      }
    }
  }
}
@media only screen and (max-width: 353px) {
  .breadcrumb.is-active span {
    display: none !important;
  }
}