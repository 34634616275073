.tooltip-inner {
  background-color: @color-blueNeutral;
  max-width: 250px;
}
.tooltip-arrow {
  border-bottom-color: #547a8b !important;
}

[data-toggle="tooltip-image"] + .tooltip  {
  opacity: 1;
  .tooltip-inner {
    background-color: #fff;
    opacity: 1;
    min-width: 100px;
    height: 100px;
    line-height: 92px;
    .box-shadow(0; 0; 10px; 1px; #ddd);
  }
  .tooltip-arrow {
    display: none;
  }
}
[data-toggle="tooltip-image"] {
  input, label {
    vertical-align: top;
  }
}