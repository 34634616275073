.commonFilter {
  color: @color-blueNeutral;
  border-radius: 17px;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  margin-right: 15px;
  border: 2px solid @color-blueNeutral;
  padding: 3px 10px;
}
.commonFilter.is-active {
  background-color: @color-blueNeutral;
  color: @color-brandWhite;
}
.checkbox-filter-spacer {
  margin-bottom: 2em;
}
