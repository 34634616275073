.brandFilter {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.filter-brand--item {
  text-align: center;
  background-color: @color-brandWhite;
}
.filter-brand--item.is-inactive {
  border-width: 0;
  cursor: default;
  padding: 15px 8px 18px;
}
.filter-brand--label {
  width: 100%;
  cursor: pointer;
  padding: 15px 8px 18px;
  margin-bottom: 0;
}
.filter-brand--label:hover,
.filter-brand--item.is-active .filter-brand--label {
  border-bottom-style: solid;
  border-width: 3px;
  padding-bottom: 15px;
}
.filter-brand--image {
  height: 17px;
  width: auto;
  pointer-events: none;
}



@media @media--tablet {
  .specificationFilter .brandFilter {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .brandFilter {
    flex-wrap: wrap;
  }
  .filter-brand--item {
    flex: 1 0 13%;
  }
}
