.modal-body-announcement {
  font-size: @fontSize-large;
}
.modal-announcement img {
  max-width: 100%;
}
#modal-announcement a {
  color: @color-blueNeutral;
}
/*
 * Card Stack
 */

@default-diff: 12px;
@default-width: 100%;
@default-min: 10%;
@max-number-of-cards: 5;

.card-stack {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 450px;
  height: 169px;

  .buttons {
    display: none;
    position: absolute;
    width: 35px;
    height: 35px;
    left: 0;
    top: 55%;
    color: @color-brandWhite--button;
    text-align: center;
    line-height: 35px;
    text-decoration: none;
    font-size: 22px;
    z-index: 100;
    outline: none;
    transition: all 0.2s ease;
    &:hover {
      transform: scale(1.3, 1.3);
    }
  }

  .prev {
    left: 15px;
    right: auto;
  }

  .next {
    left: auto;
    right: 15px;
  }

  .card-list {
    width: 300px;

    li {
      transition: all 100ms ease-in-out;
      border-radius: 2px;
      position: absolute;
      list-style: none;
      height: 135px;
      background-color: @color-blueNeutral;
      color: @color-brandWhite;
      -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.5);
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;

      display: none;

      button.close {
        position: absolute;
        top: 0;
        right: 5px;
        color: @color-brandWhite--button;
        opacity: .8;
        font-weight: normal;
        &:hover {
          opacity: 1;
        }
      }

      .read-more {
        position: absolute;
        right: 60px;
        text-decoration: underline;
        font-weight: bold;
        float: right;
        &:focus {
          outline: none;
        }
      }
      .show-later {
        position: absolute;
        left: 60px;
        text-decoration: underline;
        font-weight: bold;
      }

      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: block;
      }
      &:nth-child(3) {
        display: block;
      }
    }

  }

  &:hover {
    > .buttons.prev {
      display: block;
      animation: bounceInLeft 200ms;
    }

    > .buttons.next {
      display: block;
      animation: bounceInRight 200ms;
    }
  }
}
.transformThis {
  animation: scaleDown 500ms;
}
.transformPrev {
  animation: scaleUp 100ms;
  display: none;
}
@keyframes scaleUp {
  0% {
    transform: scale(1.2) translateY(50px);
    opacity: 0;
  }
  20% {
    transform: scale(1.15) translateY(40px);
    opacity: 0.10;
  }
  40% {
    transform: scale(1.10) translateY(30px);
    opacity: 0.20;
  }
  60% {
    transform: scale(1.05) translateY(20px);
    opacity: 0.40;
  }
  80% {
    transform: scale(1.01) translateY(10px);
    opacity: 0.80;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20% {
    transform: scale(1.01) translateY(20px);
    opacity: 0.80;
  }
  40% {
    transform: scale(1.05) translateY(40px);
    opacity: 0.40;
  }
  60% {
    transform: scale(1.10) translateY(60px);
    opacity: 0.20;
  }
  80% {
    transform: scale(1.15) translateY(80px);
    opacity: 0.10;
  }
  100% {
    transform: scale(1.2) translateY(100px);
    opacity: 0;
  }
}
@keyframes scaleCard {
  0% {
    top: 5px;
  }
  100% {
    top: 24px;
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  // 60% { opacity: 1; transform: translateX(20px); }
  // 80% { transform: translateX(20px); }
  100% {
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  // 60% { opacity: 1; transform: translateX(-20px); }
  // 80% { transform: translateX(20px); }
  100% {
    transform: translateX(0);
  }
}
