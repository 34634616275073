/*
 * The shadowBox is an important part of Ofa's branding.
 */

.shadowBox {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 30px;
  .box-shadow(0; 0; 20px; 2px; #bbb);
  background-color: white;
}
.shadowBox--noTopShadow {
  .box-shadow(0; 5px; 20px; -1px; #bbb);
}
.shadowBox-headline {
  margin: 20px 0;
  color: @color-brandNeutral--shadowBox-headline;
  font-size: @fontSize-large--shadowBox-headline;
  font-family: @fontFamily-bold--shadowBox-headline;
}

.shadowBox-headline.shadowBox-headline-toplevel {
	color: #000;
}
