.nav-tabs {
  border-bottom: 2px solid @color-brandNeutral;
  color: @color-brandNeutral;
  display: none;
  font-size: @fontSize-large;
  font-weight: bold;
}
.nav-tabs>li {
  height: 35px;
}
.nav-tabs>li>a {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: @color-brandLight;
  border-color: @color-brandNeutral;
}
.nav-tabs>li>a:hover {
  border-color: @color-brandNeutral;
}
.nav>li>a:hover, .nav>li>a:focus {
  background-color: @color-brandLight;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
  color: @color-brandNeutral;
  background-color: transparent;
  border-color: @color-brandNeutral;
}

@media only screen and (max-width: 830px) {
  /* Allows up to 5 Tabs in a modal on Portrait 10-inch Tablet */
  .modal-content .nav-tabs>li>a {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.nav-tabs--mobile {
  display: none;
}
.mobile-filter-toggle {
  display: none;
}
@media @media--tablet {
  .mobile-filter-toggle {
    display: block;
    background: none;
    border: none;
    padding: 0;
  }
  .nav-tabs.js-categoryTabs {
      display: none;
  }
  .nav-tabs--mobile {
    display: block;
  }
}

.nav-tabs.mobile {
  background-color: #fff;
  font-size: 13px;
  display: flex;
  border-top: 2px solid @color-brandNeutral;
  width: 100%;
  > li {
    height: auto;
    text-align: center;
    padding: 10px 5px;
    border-bottom: 3px solid transparent;
    > a {
      background-color: transparent;
      border: 0;
      padding: 0;
      :hover {
        border: 0;
      }
    }
    &.active {
      color: @color-blueNeutral;
      border-bottom: 3px solid @color-blueNeutral;
    }
    span {
      display: block;
      &.glyphicon {
        font-size: 11px;
        margin-top: 2px;
      }
    }

    width: 20%;
    flex: 1 0 20%;
  }
}
@media (max-width: 575px) {
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;

    a {
      flex: 1 0 auto;
      text-align: center;
      height: unset;
      line-height: unset;
      margin: 0.3em 0 0 0;
      width: 48%;

      &:first-child {
        width: 100%;
      }

      &:nth-child(2) {
        margin-right: 5px;
      }

      &:nth-child(3) {
        margin-left: 5px;
      }
    }

    form {
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .nav-tabs {
    display: block;
  }
  .nav-tabs.mobile {
    display: none;
  }
}