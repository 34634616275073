/*
 * Messages component. Leveraging Django's messaging framework, messages are styled here.
 */

.messages {
  list-style: none;
  padding: 0;
}
.message {
  border-style: solid;
  border-width: 2px;
  padding: 10px;
}
.message b {
  font-family: @fontFamily-bold--message;
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: 5px;
}
.message--warning {
  border-color: @borderColor-brandWarning--message;
  color: @color-warning--message;
}
.message--info, .message--success {
  border-color: @borderColor-blueNeutral--message;
  color: @color-blueNeutral--message;
}
.message-closeButton {
  float: right;
  font-size: @fontSize-large--message-closeButton;
  margin-top: -5px;
  border: 0;
  background: transparent;
}
.message--warning .message-closeButton {
  color: @color-warning--message;
}
.message--info .message-closeButton, .message--success .message-closeButton {
  color: @color-blueNeutral--message;
}