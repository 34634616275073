@media @media--tablet {
    .deliveryAddress {
        white-space: pre-line;
        display: inline-block;
        max-width: 40%;
        vertical-align: top;
    }
    .deliveryAddress-th {
        vertical-align: top;
    }
}

@media @media--mobile {
    .order-list-modal {
        width: 100% !important;
        min-height: 100vh;
        max-width: unset;
    }
}