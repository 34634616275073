.employeePortrait {
  height: 280px;
}
.employeePortrait-imageContainer {
    height: 180px;
    position: relative;
}
.employeePortrait-imageContainer img {
  position: absolute;
  bottom: 0;
}

.employeePortrait-position {
  font-weight: bold;
}
@media @media--tablet {
  .contact-heading {
    text-align: center;
  }
  .contact-form {
    max-width: 60%;
    margin: auto;
    .inputGroup {
      .inputGroup-textLabel {
        margin-top: 0;
        margin-bottom: 0;
      }
      .inputGroup-textLabel.pull-left {
        float: none !important;
      }
    }
    .u-w300 {
      max-width: 100%;
      width: 100%;
    }
  }
  .contact-image-wrapper {
    display: none;
  }
}

@media @media--mobile {
  .contact-form {
    max-width: 100%;
  }
}
