/* Categories */
.categoryItem label {
  cursor: pointer;
  font-weight: normal;
}

/* Filters */
.filter-headline {
  cursor: pointer;
}
.filter-headline:after {
  font-family: "Glyphicons Halflings";
  content: "\e080";
  float: right;
  font-size: .8em;
  line-height: 21px;
  color: @color-brandNeutral;
}
.filter-headline[aria-expanded="true"]:after {
  content: "\e114";
}
.filter-headline.filter-set:after {
  display: none;
}
.filter-label {
  display: block;
  font-weight: normal;
  cursor: pointer;
  padding: 2px 5px;
  transition: all .1s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  width: 130px;
  padding-right: 10px;
}
.filter-label:hover {
  transition: all .1s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  background-color: @color-brandLight;
}
.filter-label.is-active {
  border: solid 1px @color-brandBlack;
  .icon-ofa-icon-schliessen();
}
.filter-label.is-active:after {
  /* Set the remove-glyphicon */
  line-height: 21px;
  font-size: 10px;
  float: right;
  color: @color-brandBlack;
}
.filter-input {
  display: none;
}
.filter-deleteAll {
  .sidebar-fullwidthChild;
  margin-bottom: -10px; /* To get rid of the sidebars padding */
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  color: @color-brandWhite;
  background-color: @color-brandNeutral;
  font-weight: bold;
  cursor: pointer;
}
.filter-deleteAll .glyphicon {
  margin-right: 5px;
}

/*
 *Specific filter templates
 */
/* size, bodypart, sizeorthopaedic */
.filter-listItemWithIcon img {
  height: 20px;
  margin-right: 10px;
}
/* colorfilter */
.filter-color--item {
  display: inline-block;
  text-align: center;
  width: 35px;
}
.filter-color--preview {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: -6px;
  margin-left: auto;
  margin-right: auto;
  .border-radius(50%);
  position: relative;
}
.filter-background {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid @color-brandNeutral;
  .border-radius(50%);
}
.filter-color--preview.is-active {
  border: 2px solid @color-brandNeutral;
  width: 29px;
  height: 29px;
  margin-bottom: -3px;
}
.filter-color--preview.is-active .filter-background {
  width: 23px;
  height: 23px;
}
.filter-color--preview.is-active:hover .filter-color-closeButton {
  display: inline-block;
  height: 13px;
  width: 13px;
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: @bg-brandWarning--button;
  color: white;
  .border-radius(50%);
}
.filter-color--preview.is-active:hover .filter-color-closeButton {
  .icon-ofa-icon-schliessen();
}
.filter-color--preview.is-active:hover .filter-color-closeButton:after {
  // TODO background X
  position: absolute;
  top: 1px;
  left: 4px;
  font-size: 5px;
  line-height: 12px;
}
.filter-color--item label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
}
/* griptopfilter */
.filter-griptop--item {
  display: inline-block;
  text-align: center;
  width: 49%;
}
.filter-griptop--image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.filter-griptop--image.is-active {
  border: 2px solid @color-brandNeutral;
}
.filter-griptop--item label {
  cursor: pointer;
  font-weight: normal;
}
/* variationfilter */
.filter-variation--item {
  display: inline-block;
  text-align: center;
  width: 23%; /* There are 4 variants */
  padding-top: 8px;
}
.filter-variation--image {
  width: 35px;
  height: 55px;
}
.filter-variation--item.is-active {
  border: 2px solid @color-brandNeutral;
}
.filter-variation--item label {
cursor: pointer;
font-weight: normal;
}
.filter-serial-size,
.filter-custom-size,
.filter-child-size {
  margin-right: 10px;
}
.filter-serial-size,
.filter-serial-made,
.filter-seriengroe {
  .icon-ofa-icon-serienbestellung();
}
.filter-custom-size,
.filter-made-to-measure,
.filter-magroe{
  .icon-ofa-icon-massbestellung();
}
.filter-child-size,
.filter-also-for-children,
.filter-kindergroe{
  .icon-ofa-icon-kleine-groessen();
}
.filter-kleine-groen{
  .icon-ofa-icon-kleine-groessen();
}

/* selectorfilter and footfilter are default checkboxes */

/* Utilities */
.sidebar-fullwidthChild {
  /* The sidebar has a padding of 10px 30px. This class makes childs "fullwidth" in the sidebar */
  margin-left: -30px;
  margin-right: -30px;
}