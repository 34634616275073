.autocomplete-suggestions {
 border: 0;
 .box-shadow(0; 5px; 20px; 2px; #bbb);
  padding-bottom: 10px;
  position: absolute;
  background-color: @color-brandWhite;
  z-index: 10000;
}
.autocomplete-suggestion {
  font-family: @fontFamily-light;
  padding-left: 15px;
}
.autocomplete-category {
  margin-top: 10px;
  padding-left: 15px;
  font-family: @fontFamily-bold;
  color: @color-brandNeutral;
}
.autocomplete-suggestion b {
  color: @color-brandBlack;
  font-family: @fontFamily-bold;
}