
.accountDetails .nav-tabs {
    display: none;
}
.accountDetails__logo {
    display: none;
}
.mobile-edit-logo {
    display: none;
}
.mobile-logo-btn-delete {
    display: none;
}
.mobile-logo-btn-upload {
    display: none;
}
@media @media--tablet {
    .edit-logo {
        display: none;
    }
    .mobile-edit-logo {
        display: block;
        .inputGroup-textLabel {
            display: none;
        }
        .inputGroup {
            text-align: center;
        }
        img {
            border-radius: 50%;
            margin-bottom: -1.5em;
        }
    }
    .desktop-logo-btn-upload {
        display: none !important;
    }
    .desktop-logo-btn-delete {
        display: none !important;
    }
    .mobile-logo-btn-delete {
        display: inline-block;
    }
    .mobile-logo-btn-upload {
        display: inline-block;
    }
    
    .accountDetails {
        .shadowBox-headline, .sideTabs {
            display: none;
        }
        .nav-tabs {
            display: block !important;
        }
    }
    .accountDetails.shadowBox {
        width: fit-content;
        display: block;
        margin: auto;
        box-shadow: none;
        background: none;
        padding: 0;
    }
    .nav.nav-tabs > li:last-of-type a {
        margin-right: 0;
    }
    .nav.nav-tabs > li:first-of-type a {
        margin-left: 0;
    }
    .accountDetails__logo {
        display: block;
        .keyValue-key {
            display: none;
        }
        .keyValue-value {
            display: flex;
            justify-content: center;
        }
    }
    .accountDetails__logo-bottom {
        display: none;
    }
    // edit view styles
    .accountEditForm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        .inputGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            #id_street {
                width: 95%;
            }
            &.addressFields {
                grid-column-start: span 2;
                .inputGroup--inline {
                    display: block;
                    .inputGroup--sub {
                        margin-top: 0;
                        display: flex;
                        width: 97.5%;
                        justify-content: space-between;
                        .inputGroup--inline {
                            width: 49%;
                        }
                    }
                }
            }
        }
        .inputGroup--accountPostcode-textInput, .inputGroup--accountCity-textInput {
            width: 95% !important;
        }
        .inputGroup-textLabel {
            margin: .5em;
        }
        .inputGroup-textInput {
            margin: .5em;
        }
        .u-w250 {
            width: 90%;
            max-width: 100%;
        }
    }
    .accountSettings {
        .inputGroup-textLabel {
            margin-left: 0;
        }
        .inputGroup-input {
            margin: 0;
            margin-left: .5em;
        }
        .edit-submit {
            display: flex;
            flex-direction: column;
            .hint {
                position: relative !important;
                transform: unset !important;
                top: 0;
            }
        }
    }
    
}

@media @media--mobile {
    .mobileLoginWrapper {
        padding-right: 30px;
    }
    #id_street {
        width: 90% !important;
    }
    .accountEditForm {
        grid-template-columns: 1fr;
        .inputGroup.addressFields {
            grid-column: 1;
        }
    }
    .accountEditForm .inputGroup.addressFields .inputGroup--inline .inputGroup--sub {
            width: 92%;
        }
    .modal-content, .modal-dialog {
        width: 101vw;
        height: 101vh;
        margin: 0;
        display: block;
        .inputGroup {
            display: flex;
            flex-direction: column;
            * {
                margin: 0.25em 0 0.5em 0;
                width: 100%;
            }
        }
    }
    .nav-tabs > li > a {
        margin-left: 0 !important;
        padding: 1px 5px;
    }
    .edit-submit {
        display: flex;
        flex-direction: column;
        .hint {
            position: relative !important;
            transform: unset !important;
            top: 0;
        }
    }
}
@media only screen and (max-width: 325px) {
    .nav-tabs > li > a {
        margin-right: 0 !important;
        padding: 1px;
    }
}