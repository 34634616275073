@charset "UTF-8";

@font-face {
  font-family: "ofa-icons";
  src: url("../fonts/ofa-icons.eot");
  src: url("../fonts/ofa-icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/ofa-icons.woff") format("woff"),
  url("../fonts/ofa-icons.ttf") format("truetype"),
  url("../fonts/ofa-icons.svg#ofa-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}
.ofa-icon:before {
  display: inline-block;
  margin-top: -4px;
  margin-right: 4px;
}
.ofa-icon-small:before {
  font-size: 14px !important;
}
.ofa-icon--right {
  display: inline-block;
  margin-left: 5px;
  font-size: 15px !important;
  margin-right: -5px !important;
}
[data-icon]:before {
  font-family: "ofa-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:after {
  font-family: "ofa-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin-top: -2px;
}
.icon-ofa-icon-massbestellung() {
  &:before {
    content: "\57";
  }
}
.icon-ofa-icon-serienbestellung() {
  &:before {
    content: "\58";
  }
}
.icon-ofa-icon-ortho-kinder() {
  &:before {
    content: "\41";
  }
}
.icon-ofa-icon-kleine-groessen() {
  &:before {
    content: "\5A";
    font-size: 22px;
    margin-left: -8px;
  }
}
.icon-ofa-icon-schliessen() {
  &:after {
    content: "\54";
    font-family: "ofa-icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }
}
.icon-ofa-icon-information--redHover:hover:before,
.icon-ofa-icon-customer--redHover:hover:before {
  color: @color-brandWarning;
}
.icon-ofa-icon-zurueck:before {
  content: "\50";
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-ofa-icon-zurueck-kreis:before {
  content: "\51";
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-ofa-icon-aendern:before {
  content: "\62";
}
.icon-ofa-icon-aendern-bestellen:before {
  content: "\63";
}
.icon-ofa-icon-alternativen:before {
  content: "\64";
}
.icon-ofa-icon-bestellhinweiss-leer:before {
  content: "\66";
}
.icon-ofa-icon-bestellhinweiss-voll:before {
  content: "\67";
}
.icon-ofa-icon-bestellubersicht:before {
  content: "\68";
}
.icon-ofa-icon-customer:before {
  content: "\69";
}
.icon-ofa-icon-drucken:before {
  content: "\6a";
}
.icon-ofa-icon-filter-bestellungen:before {
  content: "\6c";
}
.icon-ofa-icon-groessenfinder:before {
  content: "\6d";
}
.icon-ofa-icon-hinzufuegen:before {
  content: "\6e";
}
.icon-ofa-icon-information:before {
  content: "\6f";
}
.icon-ofa-icon-kontakt:before {
  content: "\70";
}
.icon-ofa-icon-kostenvoranschlag:before {
  content: "\71";
}
.icon-ofa-icon-kunde:before {
  content: "\72";
}
.icon-ofa-icon-kundenbestellung:before {
  content: "\73";
}
.icon-ofa-icon-kundenkartei:before {
  content: "\74";
}
.icon-ofa-icon-lager:before {
  content: "\75";
}
.icon-ofa-icon-lagerbestellung:before {
  content: "\76";
}
.icon-ofa-icon-messgeraet:before {
  content: "\77";
}
.icon-ofa-icon-nachkaufschreiben:before {
  content: "\78";
}
.icon-ofa-icon-nachsorgechreiben:before {
  content: "\79";
}
.icon-ofa-icon-neuer-kunde:before {
  content: "\7a";
}
.icon-ofa-icon-ortho-kinder:before {
  content: "\41";
}
.icon-ofa-icon-kleine-groessen:before {
  content: "\5A";
  font-size: 24px;
  margin-top: -8px;
}
.icon-ofa-icon-pfeil:before {
  content: "\42";
}
.icon-ofa-icon-pin-linie:before {
  content: "\43";
}
.icon-ofa-icon-rabatt:before {
  content: "\44";
}
.icon-ofa-icon-retoure-12:before, .icon-ofa-icon-retoure:before {
  content: "\47";
}
.icon-ofa-icon-speichern:before {
  content: "\48";
}
.icon-ofa-icon-status-angekommen:before {
  content: "\49";
}
.icon-ofa-icon-status-bearbeitung:before {
  content: "\4a";
}
.icon-ofa-icon-status-erledigt:before {
  content: "\4b";
}
.icon-ofa-icon-status-offen:before {
  content: "\4c";
}
.icon-ofa-icon-suche:before {
  content: "\4d";
}
.icon-ofa-icon-warenkorb:before {
  content: "\4e";
}
.icon-ofa-icon-warnung:before {
  content: "\4f";
}
.icon-ofa-icon-weiter:before {
  content: "\50";
}
.icon-ofa-icon-weiter-kreis:before {
  content: "\51";
}
.icon-ofa-icon-filialkunde:before {
  content: "\52";
}
.icon-ofa-icon-loeschen:before {
  content: "\53";
}
.icon-ofa-icon-schliessen:before {
  content: "\54";
}
.icon-ofa-icon-filter:before {
  content: "\56";
}
.icon-ofa-icon-massbestellung:before {
  content: "\57";
}
.icon-ofa-icon-serienbestellung:before {
  content: "\58";
}
.icon-ofa-icon-pin-gefuellt:before {
  content: "\59";
}
.icon-ofa-icon-abmelden:before {
  content: "\61";
}
.icon-ofa-icon-account:before {
  content: "\65";
}
.icon-ofa-icon-anmelden:before {
  content: "\6b";
}
.icon-ofa-icon-fachhandelsportal:before {
  content: "\45";
}
.icon-ofa-icon-toleranzen:before {
  content: "\46";
}
.icon-ofa-icon-schnellbestellung:before {
  content: "\55";
}

[class^="icon-portal-"]:before, [class*=" icon-portal-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ofa-portal' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-portal-download:before {
  content: "\e906";
}
.icon-portal-aktualisieren:before {
  content: "\e90a";
}
.icon-portal-beliebte-produkte:before {
  content: "\e90b";
}
.icon-portal-burgermenu:before {
  content: "\e90c";
}
.icon-portal-drucken:before {
  content: "\e90d";
}
.icon-portal-filter:before {
  content: "\e90e";
}
.icon-portal-info:before {
  content: "\e90f";
}
.icon-portal-karriere:before {
  content: "\e910";
}
.icon-portal-koerperwissen:before {
  content: "\e911";
}
.icon-portal-krankheitsbild:before {
  content: "\e912";
}
.icon-portal-loeschen:before {
  content: "\e913";
}
.icon-portal-pfeil:before {
  content: "\e914";
}
.icon-portal-pin:before {
  content: "\e915";
}
.icon-portal-presse:before {
  content: "\e916";
}
.icon-portal-produktfinder:before {
  content: "\e917";
}
.icon-portal-schlieen:before {
  content: "\e918";
}
.icon-portal-speichern:before {
  content: "\e919";
}
.icon-portal-suche:before {
  content: "\e91a";
}
.icon-portal-therapie:before {
  content: "\e91b";
}
.icon-portal-weiter:before {
  content: "\e91c";
}
.icon-portal-weiter-kreis:before {
  content: "\e91d";
}
.icon-portal-facebook:before {
  content: "\e900";
}
.icon-portal-twitter:before {
  content: "\e901";
}
.icon-portal-instagram:before {
  content: "\e902";
}
.icon-portal-googleplus:before {
  content: "\e903";
}
.icon-portal-linkedin:before {
  content: "\e904";
}
.icon-portal-xing:before {
  content: "\e905";
}
