/*
 * Utilities for styling
 */

/* Display */
.u-displayBlock { display: block; }
.u-inlineBlock { display: inline-block; }
.u-inlineBlock-important { display: inline-block !important; }
.u-inline { display: inline; }
.u-displayNone { display: none; }
.u-smaller { font-size: @fontSize-smaller; }
.u-small { font-size: @fontSize-small; }
.u-base { font-size: @fontSize-base; }
.u-floatRight { float: right; }
.u-floatLeft { float: left; }

/* Margins */

.u-marginTop20--negative { margin-top: -20px; }
.u-marginTop35--negative { margin-top: -35px; }
.u-marginTop50--negative { margin-top: -50px; }
.u-marginTop0 { margin-top: 0; }
.u-marginTop5 { margin-top: 5px; }
.u-marginBottom0 { margin-bottom: 0; }
.u-marginHeight5 { margin: 5px 0;}
.u-marginBottom5 { margin-bottom: 5px; }
@media (max-width: 767px) { .u-xs-marginBottom20 { margin-bottom: 20px; } }

.u-marginLeft10 { margin-left: 10px; }
.u-marginRight10 { margin-right: 10px; }
.u-marginTop10 { margin-top: 10px; }
.u-marginBottom10 { margin-bottom: 10px; }
.u-marginWidth10 { margin: 0 10px; }
.u-marginHeight10 { margin: 10px 0; }

.u-marginLeft15 { margin-left: 15px; }
.u-marginRight15 { margin-right: 15px; }
.u-marginTop15 { margin-top: 15px; }
.u-marginBottom15 { margin-bottom: 15px; }

.u-marginTop18 { margin-top: 18px; }

.u-marginLeft20 { margin-left: 20px; }
.u-marginLeft200 { margin-left: 200px; }
.u-marginRight20 { margin-right: 20px; }
.u-marginTop20 { margin-top: 20px; }
.u-marginBottom20 { margin-bottom: 20px; }
.u-marginWidth20 { margin: 0 20px; }
.u-marginHeight20 { margin: 20px 0; }

.u-marginLeft25 { margin-left: 25px; }
.u-marginRight25 { margin-right: 25px; }
.u-marginTop25 { margin-top: 25px; }
.u-marginBottom25 { margin-bottom: 25px; }
.u-marginWidth25 { margin: 0 25px; }
.u-marginHeight25 { margin: 25px 0; }

.u-marginLeft30 { margin-left: 30px; }
.u-marginRight30 { margin-right: 30px; }
.u-marginTop30 { margin-top: 30px; }
.u-marginBottom30 { margin-bottom: 30px; }
.u-marginWidth30 { margin: 0 30px; }
.u-marginHeight30 { margin: 30px 0; }

.u-marginTop40 { margin-top: 40px; }
.u-marginRight40 { margin-right: 40px; }

.u-marginLeft50 { margin-left: 50px; }
.u-marginRight50 { margin-right: 50px; }
.u-marginTop50 { margin-top: 50px; }
.u-marginBottom50 { margin-bottom: 50px; }
.u-marginWidth50 { margin: 0 50px; }
.u-marginHeight50 { margin: 50px 0; }
.u-marginTop80 { margin-top: 80px; }
.u-marginTop100 { margin-top: 100px; }
.u-marginTop150 { margin-top: 150px; }

/* Paddings */

.u-paddingRight0 { padding-right: 0; }
.u-paddingLeft10 { padding-left: 10px; }
.u-paddingRight10 { padding-right: 10px; }
.u-paddingBottom10 { padding-bottom: 10px; }
.u-paddingTop10 { padding-top: 10px; }
.u-paddingWidth10 { padding: 0 10px; }
.u-paddingHeight10 { padding: 10px 0; }

.u-paddingLeft15 { padding-left: 15px; }

.u-paddingLeft20 { padding-left: 20px; }
.u-paddingTop20 { padding-top: 20px; }
.u-paddingBottom20 { padding-bottom: 20px; }
.u-paddingRight20 { padding-right: 20px; }
.u-paddingWidth20 { padding: 0 20px; }
.u-paddingHeight20 { padding: 20px 0; }

.u-paddingLeft25 { padding-left: 25px; }
.u-paddingTop25 { padding-top: 25px; }
.u-paddingBottom25 { padding-bottom: 25px; }
.u-paddingRight25 { padding-right: 25px; }
.u-paddingWidth25 { padding: 0 25px; }
.u-paddingHeight25 { padding: 25px 0; }


.u-paddingLeft50 { padding-left: 50px; }
.u-paddingRight50 { padding-right: 50px; }
.u-paddingWidth50 { padding: 0 50px; }
.u-paddingHeight50 { padding: 50px 0; }

.u-paddingLeft200 { padding-left: 200px; }

.u-noPaddingNoMargin { padding: 0;margin: 0; }
.u-noPadding { padding: 0 !important; }
.u-noMargin { margin: 0 !important; }

/* Width & Height */
.u-maxWidth100 { max-width: 100%; }
.u-maxWidth50 { max-width: 50%; }
.u-maxWidth30 { max-width: 30%; }
.u-maxWidth300 { max-width: 300px;}
.u-maxHeight100 { max-height: 100%; }
.u-maxHeight50 { max-height: 50%; }
.u-maxHeight30 { max-height: 30%; }

.u-wAuto { width: auto; }
.u-w40 { width: 40px; }
.u-w50 { width: 50px; }
.u-w50i { width: 50px !important; }
.u-w70 { width: 70px; }
.u-w80 { width: 80px; }
.u-w90 { width: 80px; }
.u-w100 { width: 100px; }
.u-w120 { width: 120px; }
.u-w130 { width: 130px; }
.u-w150 { width: 150px; }
.u-w160 { width: 160px; }
.u-w200 { width: 200px; }
.u-w240 { width: 240px; }
.u-w250 { width: 250px; }
.u-w300 { width: 300px; }
.u-w330 { width: 330px; }
.u-w340 { width: 340px; }
.u-w380 { width: 380px; }
.u-w20P { width: 20%; }
.u-w40P { width: 40%; }
.u-w80P { width: 80%; }
.u-w60P { width: 60%; }
.u-w100P { width: 100%; }
.u-h100 { height: 100px; }
.u-h20 { height: 20px; }
.u-h30 { height: 30px; }
.u-h50 { height: 50px; }
.u-h80 { height: 80px; }
.u-h70P { height: 70%; }
.u-h100P { height: 100%; }
.u-h250 { height: 250px; }
.u-minHeight150 { min-height:150px; }
.u-minHeight450 { min-height:450px; }

/* Visibility */
.u-visibilityHidden { visibility: hidden; opacity: 0; }

.u-overflow--hidden { overflow: hidden; }
.u-overflow--auto { overflow: auto; }

.u-translate (@x, @y:0) {
	-webkit-transform:   translate(@x, @y);
	-moz-transform: 	 translate(@x, @y);
	-ms-transform: 		 translate(@x, @y);
	-o-transform: 		 translate(@x, @y);
}

/* Centering */
/* This uses the merge-functionality (+_) of less on the transform declarations. */
.u-centerContainer { position: relative; }
.u-centerVertically { position: absolute; top: 50%; transform+_: translateY(-50%); }
.u-centerHorizontally { position: absolute; left: 50%; transform+_: translateX(-50%); }
.u-centerVerticallyHorizontally { .u-centerVertically; .u-centerHorizontally; }

/* Transitions */
.u-transitionEaseInOut2 {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.u-underline { text-decoration: underline; }
.u-bold { font-family: @fontFamily-bold; }
.u-bold-important { font-family: @fontFamily-bold !important; }
.u-light { font-family: @fontFamily-light; }
.u-italic { font-family: @fontFamily-italic; }
.u-uppercase { text-transform: uppercase; }
.u-fontSizeLarge { font-size: @fontSize-large; }
.u-fontSizeSmall { font-size: @fontSize-smaller; }
.u-fontSizeSmall-hint { font-size: @fontSize-small--hint; }
.u-lineThrough { text-decoration: line-through; }

.u-colorBrandNeutral { color: @color-brandNeutral; }

.u-desaturate {
  /* You're trying to find out how the grayscaling works?
   Because IE (and actually FF also) suck, grayscaling is done via https://github.com/karlhorky/gray/
   It's called in brand_filter.html currently. */
  opacity: 0.5;
  cursor: default;
  &:before {
    cursor: default;
  }
}

.u-listStyleDash {
  margin-top: 5px;
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
.u-listStyleDash > li:before {
  display: inline-block;
  content: '\2013';
  width: 1em;
  margin-left: -1em;
  margin-right: -0.5em;
}

.u-colorBrandBlack { color: @color-brandBlack !important;}
.u-color-brandWarning { color: @color-warning--message; }
.u-color-blueLightest { color: @color-blueLightest; }
.u-color-blueLighter { color: @color-blueLighter; }
.u-color-blueLight { color: @color-blueLight; }
.u-color-blueNeutral { color: @color-blueNeutral; }
.u-color-blueDark { color: @color-blueDark; }
.u-color-blueDarker { color: @color-blueDarker; }
.u-color-blueDarkest { color: @color-blueDarkest; }

/*
 * Brand-Color for Font, Background and Border
 */
.u-colorbrandNeutral-font { color: @color-brandNeutral; }
.u-colorbrandNeutral-background { background-color: @color-brandNeutral; }
.u-colorbrandNeutral-border { border-color: @color-brandNeutral; }

.u-colorBrandBlack-font { color: @color-brandBlack; }
.u-colorBrandBlack-background { background-color: @color-brandBlack; }
.u-colorBrandBlack-border { border-color: @color-brandBlack; }

.u-colorBrandDark-font { color: @color-brandDark; }

.u-color-memory-font { color: @color-memory; }
.u-color-memory-background { background-color: @color-memory; }
.u-color-memory-border { border-color: @color-memory; }

.u-color-MemoryAloevera-font { color: @color-memoryAloevera; }
.u-color-MemoryAloevera-background { background-color: @color-memoryAloevera; }
.u-color-MemoryAloevera-border { border-color: @color-memoryAloevera; }

.u-color-lastofa-font { color: @color-lastofa; }
.u-color-lastofa-background { background-color: @color-lastofa; }
.u-color-lastofa-border { border-color: @color-lastofa; }

.u-color-gilofa-font { color: @color-gilofa; }
.u-color-gilofa-background { background-color: @color-gilofa; }
.u-color-gilofa-border { border-color: @color-gilofa; }

.u-color-GilofaAdditional-font { color: @color-gilofa-additional; }
.u-color-GilofaAdditional-background { background-color: @color-gilofa-additional; }
.u-color-GilofaAdditional-border { border-color: @color-gilofa-additional; }

.u-color-o-motion-font { color: @color-omotion; }
.u-color-o-motion-background { background-color: @color-omotion; }
.u-color-o-motion-border { border-color: @color-omotion; }

.u-color-rathgeber-thermo-font { color: @color-rathgeber; }
.u-color-rathgeber-thermo-background { background-color: @color-rathgeber; }
.u-color-rathgeber-thermo-border { border-color: @color-rathgeber; }

.u-color-Ofafit-font { color: @color-ofafit; }
.u-color-Ofafit-background { background-color: @color-ofafit; }
.u-color-Ofafit-border { border-color: @color-ofafit; }

.u-color-standardma-font { color: @color-standard; }
.u-color-standardma-background { background-color: @color-standard; }
.u-color-standardma-border { border-color: @color-standard; }

.u-color-psb-font { color: @color-psb; }
.u-color-psb-background { background-color: @color-psb; }
.u-color-psb-border { border-color: @color-psb; }

.u-color-push-font { color: @color-push; }
.u-color-push-background { background-color: @color-push; }
.u-color-push-border { border-color: @color-push; }

.u-color-push-sports-font { color: @color-push-sports; }
.u-color-push-sports-background { background-color: @color-push-sports; }
.u-color-push-sports-border { border-color: @color-push-sports; }

.u-color-dynamics-font { color: @color-dynamics; }
.u-color-dynamics-background { background-color: @color-dynamics; }
.u-color-dynamics-border { border-color: @color-dynamics; }

.u-color-dynamics-plus-font { color: @color-dynamics-plus; }
.u-color-dynamics-plus-background { background-color: @color-dynamics-plus; }
.u-color-dynamics-plus-border { border-color: @color-dynamics-plus; }

.u-color-pflege-font { color: @color-pflege; }
.u-color-pflege-background { background-color: @color-pflege; }
.u-color-pflege-border { border-color: @color-pflege; }

.u-color-zubehor-font { color: @color-zubehor; }
.u-color-zubehor-background { background-color: @color-zubehor; }
.u-color-zubehor-border { border-color: @color-zubehor; }

.u-cursorPointer {
  cursor: pointer;
}
.u-verticalAlignTop {
  vertical-align: top;
}
.u-verticalAlignMiddle {
  vertical-align: middle;
}
.u-noClick {
  cursor: default !important;
}
.u-noPointerEvent {
  pointer-events: none;
}
.u-mirror {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.u-mirror:before {
  .u-mirror();
}
.u-ofaColor {
  color: @color-brandNeutral;
}
.u-ofaColor:hover {
  color: @color-brandDark;
}
.u-pullTop10 {
  margin-top: -10px;
}
.u-pullTop20 { margin-top: -20px; }
.u-pullTop30 { margin-top: -30px; }
.u-pullBottom10 {
  margin-bottom: -10px;
}

.u-consigneePatient {
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  border-width: 2px;
  border-color: @color-brandNeutral;
  color: @color-brandNeutral;
  border-style: solid;
  width: 20px;
  text-align: center;
  line-height: 16px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}
.u-textAlign--center { text-align: center; }
.u-textAlign--left { text-align: left; }
.u-textAlign--right { text-align: right; }
.u-subTitle {
  font-size: @fontSize-large;
  display: inline-block;
}
.u-noBorder { border: 0; }
.u-borderRight { border-right: 1px solid @color-brandBlack; }
.u-warning {
  color: @color-brandWarning;
  font-family: @fontFamily-italic;
}
.u-success { color: @color-brandSuccess; }
.u-underline {
  text-decoration: underline;
}

.u-positionRelative{ position: relative; }

.u-specificationWarning{
  font-family: @fontFamily-italic;
  color: @color-brandWarning;
  margin-top: -15px;
  margin-bottom: 10px;
}
.u-measurementLetter {
  font-family: @fontFamily-measurement;
}
.u-disabled {
  opacity: 0.5;
}

.u-measurementKeyValue {
  text-align: right;
}

.u-whiteSpace--normal {
  white-space: normal
}

@media print {
  .u-noPrint {
    display: none;
  }
  .u-noWrap {
    white-space: nowrap;
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {
  .u-applyLeg {
    font-size: @fontSize-smaller;
  }
  .u-measurementKeyValue {
    text-align: left;
  }
}
.u-colorTitle {
  white-space: normal;
  vertical-align: middle;
  display: inline-block;
}
[data-toggle="tooltip"] {
  cursor: pointer;
}
.hiddenAnchor {
    display: block;
    position: relative;
    top: -89px; /* size of the fixed header */
    visibility: hidden;
}
.u-noWrap {
    white-space: nowrap;
}


@media @media--mobile {
  .u-wAuto { width: auto; }
  .u-w40 { width: 100%; max-width: 40px; }
  .u-w50 { width: 100%; max-width: 50px; }
  .u-w50i { width: 100%; max-width: 50px !important; }
  .u-w70 { width: 100%; max-width: 70px; }
  .u-w80 { width: 100%; max-width: 80px; }
  .u-w90 { width: 100%; max-width: 80px; }
  .u-w100 { width: 100%; max-width: 100px; }
  .u-w120 { width: 100%; max-width: 120px; }
  .u-w130 { width: 100%; max-width: 130px; }
  .u-w150 { width: 100%; max-width: 150px; }
  .u-w160 { width: 100%; max-width: 160px; }
  .u-w200 { width: 100%; max-width: 200px; }
  .u-w240 { width: 100%; max-width: 240px; }
  .u-w250 { width: 100%; max-width: 250px; }
  .u-w300 { width: 100%; max-width: 300px; }
  .u-w330 { width: 100%; max-width: 330px; }
  .u-w340 { width: 100%; max-width: 340px; }
  .u-w380 { width: 100%; max-width: 380px; }
  .u-w20P { width: 100%; max-width: 20%; }
  .u-w40P { width: 100%; max-width: 40%; }
  .u-w80P { width: 100%; max-width: 80%; }
  .u-w60P { width: 100%; max-width: 60%; }
  .u-w100P { width: 100%; }
  .u-h100 { height: 100px; }
  .u-h20 { height: 20px; }
  .u-h30 { height: 30px; }
  .u-h50 { height: 50px; }
  .u-h80 { height: 80px; }
  .u-h70P { height: 70%; }
  .u-h100P { height: 100%; }
  .u-h250 { height: 250px; }
  .u-minHeight150 { min-height:150px; }
  .u-minHeight450 { min-height:450px; }
}

@media(max-width: 500px) {
  .u-noPaddingMobile {
    padding: 0 !important;
  }
}

.hamburger path, .mobile-basket-link path, .mobile-navbar path {
  display: inline !important;
}