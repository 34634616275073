/*
 * Sidetabs are used for sub-navigations e.g. account settings
 */

.sideTabs {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
  width: 170px;
}
.sideTabs-tab {
  padding-top: 8px;
  padding-right: 50px;
  position: relative;
}
.sideTabs-tab a,
.sideTabs-tab a:visited
.sideTabs-tab a:link,
.sideTabs-tab a:active {
  color: @color-brandBlack--sideTabs-tab;
  text-decoration: none;
}
.sideTabs-tab.is-active:after {
  content: '\e258';
  position: absolute;
  right: 0;
  top: 11px;
  color: @color-warning--sideTabs-tab;
  font-family: 'Glyphicons Halflings';
  font-size: @fontSize-smaller;
}
.sideTabs-tab.is-active a,
.sideTabs-tab.is-active a:active,
.sideTabs-tab.is-active a:visited,
.sideTabs-tab.is-active a:link,
.sideTabs-tab.is-active a:hover {
  color: @color-warning--sideTabs-tab;
  font-family: @fontFamily-bold--sideTabs-tab;
}
