.supplementTile {
  float: left;
  width: 20%;
  padding: 0 15px;
}
.supplementTile-headline {
  font-size: @fontSize-large;
  height: 50px;
  vertical-align: bottom;
  display: table-cell;
}
.supplementTile-image {
  max-width: 100%;
  line-height: 200px;
  max-height: 200px;
}
.supplementTile-imageContainer {
    text-align:center;
  width: 100%;
  height: 200px;
}
.supplementTile-description {
    height: 4em;
    overflow: hidden;
}
.supplementTile-amount {
    margin-top: -18px;
}
.supplementTile-claim {
  line-height: 1em;
}

@media (max-width: @screen-lg-min) {
  .supplementTile-claim {
    line-height: 1em;
    height: 55px;
  }
}
@media (max-width: 1400px) {
  .supplementTile-claim {
    height: 40px;
  }
}
@media @media--tablet {
  .productSpec, .js-specificationButtons {
    max-width: 90%;
    margin: auto;
  }
  .js-specificationButtons {
    float: none !important;
  }
  .productSpec {
    .brandFilter {
      display: flex;
    }
  }
  .crossselling-wrapper {
    max-width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    .supplementTile {
      width: 100%;
    }
  }
}
@media @media--mobile {
  .crossselling-wrapper {
    grid-template-columns: 1fr;
  }
}