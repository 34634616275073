.measurementKeyValue:nth-child(odd) {
  background-color: @color-brandLight;
}
.measurementKeyValue:nth-child(even) {
  background-color: @color-brandWhite;
}
.measurementKeyValue {
  padding: 5px 10px;
  border-bottom: 1px solid @color-brandNeutral;
}
.measurementKeyValue-helper {
  margin-top:69px;
  border-bottom: 1px solid @color-brandNeutral;
}
.measurementKeyValue-error {
  color: #9d261d;
}
.measurementKeyValue-value input {
  max-width: 70%;
}
.measurementKeyValue-value i {
  float: right;
}


//@media only screen and (max-width: 1280px) {
//  .measurementKeyValue {
//    padding: 5px 5px;
//  }
//}