.u-price-font,
.u-price-fontSupplement,
.u-price-font-crossSelling {
  color: #8c8c8c;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 10px;
}
.u-price-fontAdMed {
  color: @color-brandBlack;
  font-size: @fontSize-small;
  margin-top: 10px;
}
.supplementTile-amount {
    text-align: right;
}
.inputGroup-price {
    float: left;
    margin-top: 20px;

    @media (max-width: 1260px) {
        float: none;
        margin-top: 28px;
        margin-bottom: -10px;
    }
}
.specificationPrice {
    font-size: 18px;
    font-weight: bold;
    display: block;
}
.specificationPrice .js-listPrice {
    font-size: 16px;
    margin-right: -23px;
}
.specificationPrice .js-price,
.specificationPrice .js-yourPrice{
    color: @color-bluePortal;
}
.specificationPrice .js-priceFrom {
    margin-right: -20px;
}

.js-basketGroupTable .js-singlePrice,
.js-basketGroupTable .js-price {
    font-size: 18px;
    //font-weight: bold;
}
.js-basketGroupTable .js-singlePrice:after,
.js-basketGroupTable .js-price:after,
.buttons-price .js-priceFrom:after,
.priceCurrency:after,
.js-basketNetPrice:after,
.js-price .js-priceField:after,
.js-yourPrice .js-priceField:after,
.js-priceFrom .js-priceFromField:after,
.js-listPrice .js-listPriceField:after
{
    content: ' €';
    font-family: Arial,sans-serif;
}
.crossSellingCurrency,
.supplementCurrency,
.orderHistoryCurrency {
    font-family: Arial,sans-serif;
}


.basketNetPrice,
.basketNetPriceDescription {
    font-size: 18px;
    font-weight: bold;
    visibility: hidden;
}
.basketNetPriceDescription {
    text-align: right;
}

// Price gets rendered below each basket group (stock, customer) so it's aligned beautifully.
// We only show it for the last basket group.
.panel-group:last-of-type .basketNetPrice,
.panel-group:last-of-type .basketNetPriceDescription {
    visibility: visible;
}