.onboardingImage {
  display: inline-block !important;
  max-height: 100%;
}
.onboardingSlide .modal-body {
  //padding-top: 40px;
  line-height: 560px;
  height: 560px;
  text-align: center;
}
.onboardingModal .slick-dots {
    margin-left: -40px;
    bottom: 35px;
}
.onboardingModal .slick-dots li button:before {
  content: '';
}
.onboardingModal .slick-dots li button {
  height: 5px;
  width: 5px;
  .border-radius(50%);
  background-color: @color-brandLight;
}
.onboardingModal .slick-dots li.slick-active button {
  background-color: @color-brandNeutral;
}
.onboardingModal .slick-list {
  z-index: 10000;
}
.onboardingSlide p, .onboardingSlide li {
  line-height: 1.4em;
  text-align: left;
  font-size: 1.3em;
}
@media screen and (max-height: 768px) {
  .onboardingImage {
    width: auto;
    max-height: 100%;
    display: inline-block !important;
    max-width: 100%;
  }
  .onboardingSlide .modal-body {
    line-height: 440px;
    height: 440px;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .onboardingSlide .modal-body {
    height: 420px;
    line-height: 420px;
  }
  .onboardingSlide p, .onboardingSlide li {
    font-size:1.1em;
    line-height: 1.3em;
  }
}