.sizeTable {
  width: 100%;
  margin: 0;
  padding: 0;
}
.sizeTable-tableHeadline {
  font-family: @fontFamily-bold;
  padding: 5px 10px;
}
.sizeTable-tableData {
  padding: 5px 10px;
  color: @color-brandBlack;
  vertical-align: top;
}
.sizeTable-tableRow {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.sizeTable--center .sizeTable-tableHeadline,
.sizeTable--center .sizeTable-tableData {
  text-align: center;
}
.sizeTable-tableData--separate {
  border-collapse: collapse;
  border-right: 15px solid white;
}
.sizeTable .sizeTable-tableData--textLeft {
  text-align: left;
}
.sizeTable-wide-highlight {
  background-color: #e4e0dc;
}