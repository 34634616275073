.video-js {
  cursor: pointer;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin: 1em auto auto -1.5em;
}
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}
