.mailingCount {
  .border-radius(50%);
  height: 13px;
  width: 13px;
  line-height: 13px;
  background-color: @color-lastofa;
  color: @color-brandWhite;
  font-size:7px;
  position: absolute;
  top: 3px;
  left: 20px;
  text-align: center;
}
.mailingCount-content {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.mailingCount--big {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 15px;
  top: -5px;
  left: 25px;
}