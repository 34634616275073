/* PUSH FOOTER */
/* These classes are used to guarantee that the footer is always at the bottom of the page*/
.main-container {
  position: relative;
}
.content {
  padding-bottom: 460px; // 300px = .footer margin + .footer height
}

/* END FOOTER PUSH */
.footer {
  margin-top: 50px;
  background-color: @color-brandLight;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-linkList {
  list-style: none;
  padding: 0;
  margin:0;
  padding-bottom: 40px;
}
.footer-category {
  padding-top: 20px;
  font-size: @fontSize-large;
}
.footer-lowerPart {
  padding-bottom: 50px;
  padding-top: 10px;
  border-top: 1px solid @color-brandBlack;
}
.footer-lowerPart-links {
  text-align: right;
}
.footer-lowerLink {
  margin-left: 70px;
}

.footer-forum-link {
  display: block;
  font-size: @fontSize-large;
  margin-top: 10px;
}

@media @media--tablet {
  .content {
    padding-bottom: 0;
  }
  .footer {
    position: relative;
    width: 100vw;
  }
  .footer .row:first-of-type {
    max-width: 100%;
    margin: auto;
    padding-top: 1em;
  }
  .footer-lowerPart-links {
    text-align: center;
  }
  .footer-lowerLink {
    margin-left: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .clear-left {
    clear: left;
  }
}

@media @media--mobile {
  .footer-category {
    margin-top: 0;
    padding-top: 0;
    &:last-of-type {
      padding-top: 1em;
    }
  }
  .footer-lowerPart-links {
    margin-top: 1em;
  }

}