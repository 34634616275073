.pagination-item {
  display: inline-block;
  text-align: center;
  line-height: 26px;
  border: 2px solid @color-brandDark;
  height: 30px;
  width: 30px;
  .border-radius(50%);
}
.pagination-item.is-active {
  border-color: @color-brandNeutral;
  background-color: @color-brandLight;
}
.pagination-item:hover {
  color: @color-brandWhite;
  background-color: @color-brandNeutral;
  border-color: @color-brandDark;
}
.pagination-item-circle {
  border-radius: 50%;
  //behavior: url(PIE.htc); /* IE Support */
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid;
  border-color: @color-brandNeutral;
}
.pagination-item-circle:before { content: ''; }
.pagination-item-circle.is-active { background-color: @color-brandDark; }