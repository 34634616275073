.additionalInformation {
  position: absolute;
  bottom: -20px;
  right: 0;
  padding-bottom: 30px;
  margin-right: 15px;
  z-index: 1000;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  cursor: pointer;
}
.needsSpecTradeFair {
  cursor: pointer;
}
.additionalInformation-tooltip {
  margin-left: -100px;
  display: none;
  position: absolute;
  right: 0;
  bottom: -90px;
  padding-bottom: 20px;
  background-color: @color-blueNeutral;
  width: 250px;
  height: 100px;
  max-width: unset !important;
}
.needsSpecTradeFair-tooltip {
  margin-left: -100px;
  display: none;
  position: absolute;
  left: 0;
  bottom: -10px;
  padding-bottom: 20px;
  background-color: @color-blueNeutral;
  width: 250px;
  height: 100px;
  z-index: 5;
}
.additionalInformation-tooltip:after, .needsSpecTradeFair-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: @color-blueNeutral transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 110px;
}
.additionalInformation-textarea, .needsSpecTradeFair-textarea {
  padding: 3px 6px;
  background-color: @color-blueNeutral;
  border-color: @color-brandWhite;
  color: @color-brandWhite;
  height: 90px;
  width: 230px;
  margin-left: 10px;
  margin-top: 5px;
  resize: none;

}
.additionalInformation-textarea::-webkit-input-placeholder, .needsSpecTradeFair-textarea::-webkit-input-placeholder {
  color: @color-brandWhite !important;
}
.additionalInformation-textarea::-moz-placeholder, .needsSpecTradeFair-textarea::-moz-placeholder {
  color: @color-brandWhite !important;
}
.additionalInformation-textarea:-moz-placeholder, .needsSpecTradeFair-textarea:-moz-placeholder{
  color: @color-brandWhite !important;
}
.additionalInformation-textarea:-ms-input-placeholder, .needsSpecTradeFair:-ms-input-placeholder {
  color: @color-brandWhite !important;
}
.additionalInformation-textarea:focus, .needsSpecTradeFair-textarea:focus {
  outline:0;
}
.additionalInformation-content, .needsSpecTradeFair-content {
  display: inline-block;
  padding-left: 10px;
  padding-top: 5px;
  color: @color-brandWhite;
  white-space: pre-wrap;
}
.additionalInformation-edit, .needsSpecTradeFair-edit {
  text-decoration: underline;
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: @color-brandWhite;
}

@media @media--mobile {
  .additionalInformation-tooltip {
    left: -44px;
    margin-left: 0;
  }
}