.discount-errors {
  color: @color-warning--message;
}

.discount-info {
  color: @color-brandSuccess;
}

.discount-icon {
  color: @color-brandSuccess;
}