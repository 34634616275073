.measurementInput-label {
  display: inline-block;
  width: 20px;
}
input + .measurementInput-labelRadio + .measurementInput-label ~ input,
input + .measurementInput-labelRadio + .measurementInput-label ~ i{
  display: none;
}
input:checked + .measurementInput-labelRadio +  .measurementInput-label ~ input,
input:checked + .measurementInput-labelRadio +  .measurementInput-label ~ i {
  display: inline-block;
}
.measurementInput-labelRadio:before {
  content: '';
  background-image: url(../../images/input-sprites.png);
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-bottom: -3px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: -4px -218px;
}
input:checked + .measurementInput-labelRadio:before {
  background-position: -4px -192px;
}
.measurementInput-label span {
    font-family: @fontFamily-measurement;
    margin-right: 0.1em;
}
.w100 .measurementInput-label {
  width: 100px;
}
.measurementInput-label {
  margin-bottom: 0;
}
.measurementInput-input {
  width: 80px;
  height: 20px;
  border: 2px solid @color-brandNeutral;
}
.measurementInput-input:focus {
  border-color: @color-blueNeutral;
  outline: none;
}
@media (max-width: 1040px) {
  .measurementInput-input {
    width: 65px;
  }
  .measurementForm h4 {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
