.spinner {
    position: absolute;
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}
.spinner:before {
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  content: "\e031"; // glyphicon-refresh
  font-size: 30px;
}
.spinner--inline {
  left: 0
}
.spinner--small {
    line-height: 15px;
}
.spinner--small:before {
  font-size: 10px;
}
.spinner--small-container {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
}
@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}
@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
