.panel {
  border: 0;
  .box-shadow(0, 0, 0, 0, #000);
}
.panel-heading {
  background-color: @color-brandLight;
}
.panel-group {
  margin-bottom: 40px;
}
.panel-title {
  font-size: 18px;
}
.collapsed .panelIndicator {
  transform: rotate(90deg);
  margin-top: 3px;
}