.hoverSearch {
  position: fixed;
  right: 8.88888%;
  //width: 550px;
  background-color: #fff;
  display: none;
}
.generalsearch:hover .hoverSearch,
.generalsearch.hover .hoverSearch {
  display: block;
}
.hoverSearch--noFHP {
  right: 4%;
}
@media @media--tablet {
  .searchHeading {
    padding-left: 10px;
  }
  .searchResultTabs {
    display: none;
  }
  .tab-content {
    .tile--product-right img {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    } 
  }
}
@media @media--mobile {
  .dataTables_empty.u-paddingTop10 {
    max-width: 100px;
    white-space: break-spaces;
  }
}