.address-label,
.address-value {
  display: inline-block;
}
.address-label {
  width: 60px;
}
.address-container {
  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
}
.address-field {
  //flex-basis: 17%;
  margin-bottom: 20px;
}
.address-title {
  font-size: @fontSize-largest;
}
.address-headline {
  font-size: @fontSize-larger;
}