/*
 * Tiles
 */

.tile {
  width: 100%;
  position: relative;
  margin-top: 20px;
  display: table;
}
.tile-row {
  display: table-row;
}
.tile-headline {
  font-size: @fontSize-large--tile-headline;
  text-align: center;
  font-family: @fontFamily-bold--tile;
  background-color: rgba(255,255,255,0.5);
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding: 15px 10px;

}
.tile-headline--large {
  height: 80px;
}
.tile-headline--medium {
  height: 60px;
}
.tileDropdown {
  bottom: 30px;
  position: absolute;
  height: 40px;
  right: 0;
  width: 100%;
}
.tile--category {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .box-shadow(0; 0; 10px; 1px; #ddd);
}
.tile-md--large {
  height: 300px;
}
.tile-md--medium {
  height: 300px;
}
.tile-arrow {
  padding: 10px 14px;
  color: @color-brandWhite--tile-arrow;
  background-color: @color-brandNeutral--tile-arrow;
  position: absolute;
  bottom: 0;
  right: 0;
}
.tile:hover .tile-arrow {
  background-color: @color-blueNeutral--tile-arrow;
}
.tile--product {
  padding: 10px 20px 15px 20px;
  border: 1px solid @color-brandLight;
  margin-top: -1px;
  margin-left: -1px;
  cursor: pointer;
  z-index: 1;
}
.tile--product:hover {
  z-index: 2;
  /* The color is set below by the certain brand-classes */
}
.tile--product-right,
.tile--product-left {
  height: 300px;
  position: relative;
  float: left;
}
.tile-col--upper,
.tile-col--lower,
.tile-col--lower-price {
  position: relative;
}
.tile-col--upper {
  height: 33%;
}
.tile-col--lower {
  height: 66%;
  padding-top: 20px;
  font-size: 1.02em;
}
.tile-col--lower-price {
  //height: 23%;
  padding-top: 5px;
}
.tile--product-left {
  /* Left container */
  width: 55%;
  padding-bottom: 30px;
  vertical-align: middle;
}
.tile--product-right {
  /* Right container */
  z-index: 1;
  width: 45%;
  color: @color-standard;
  text-align: left;
}
.tile--product-left img,
.tile--product-right img {
  position: absolute;
  bottom: 0;
}
.tile--product-right img {
  width: 100%;
}
.tile--product-left img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 220px;
  margin-left: -10px;
  bottom: 30px;
}
.tile--product-information {
  color: @color-brandNeutral;
  line-height: 28px;
}
.tile--product-information:hover {
  color: @color-brandDark;
  cursor: pointer;
}
.tile--product-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.tile--product-size-icons {
  // Currently the .tile--product-right is full-height and overlaps the size-icons.
  // However we want the title-attributes to be displayed on hover.
  position: relative;
  z-index: 1;
}
.tile--product-size-icon {
  height: 25px;
  margin-left: 10px;
  float: right;
  font-size: @fontSize-large;
  color: @color-brandNeutral;
}
.u-tileDropdown--margin {
  margin-top: 40px;
}
.u-tile--inputBottom {
    position: absolute;
    bottom: -5px;
    width: 100%;
    font-size: 0.9em;
}

.tile--product .cms-render-model-add {
  //float: left;
  //margin-bottom: -18px;
}

/* Brand hover-colors */
.tile--product.memory:hover { border-color: @color-memory; }
.tile--product.lastofa:hover { border-color: @color-lastofa; }
.tile--product.gilofa:hover { border-color: @color-gilofa; }
.tile--product.omotion:hover { border-color: @color-omotion; }
.tile--product.rathgeber-thermo:hover { /*border-color: @color-rathgeber;*/ cursor: default}
.tile--product.zubehor:hover { /*border-color: @color-zubehor;*/ cursor: default}
.tile--product.ofafit:hover { border-color: @color-ofafit; }
.tile--product.o-motion:hover { border-color: @color-omotion; }
// standardmass is still used by ofa-basic
.tile--product.standardmass:hover { border-color: @color-standard; }
.tile--product.ofa-basic:hover { border-color: @color-standard; }
.tile--product.ofa-basic-serie:hover { border-color: @color-standard; }
.tile--product.pflege:hover { /*border-color: @color-pflege;*/ cursor: default}
.tile--product.psb:hover { border-color: @color-psb; }
.tile--product.push:hover { border-color: @color-push; }
.tile--product.push-sports:hover { border-color: @color-push-sports; }
.tile--product.dynamics:hover { border-color: @color-dynamics; }
.tile--product.dynamics-plus:hover { border-color: @color-dynamics-plus; }
.tile--product.advertising { /*border-color: @color-advertising;*/ cursor: default;}
.tile--product.ecosana:hover { border-color: @color-memory; }


.tile--unavailable {
  cursor: default;
}
.tile--product__unavailable {
    background-color: #363fc4;
    color: white;
    position: absolute;
    top: 6em;
    left: 3em;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 1;
  & p {
    display: block;
    max-height: 80%;
    max-width: 82%;
    margin: 2rem auto auto;
    line-height: 1;
  }
}
.js-supplementProduct .tile--product__unavailable {
  left: 2.5em;
}

.tile--grayscale__active {
  & .tile--product-left {
    filter: grayscale(1);
  }
  & .tile--product-right, .icon-ofa-icon-information {
    filter: contrast(0.2);
  }
}
.col-lg-9 .mobile-quick-order.shadowBox {
  display: none;
}
@media (min-width: @screen-lg-min) {
  .tile-lg--large {
    height: 400px;
  }
  .tile-lg--medium {
    height: 300px;
  }
  .tile-col--lower {
    padding-top: 5px;
  }
}
@media (max-width: @screen-lg-min) {
  .tile-headline--large {
    height: 60px;
  }
  .tile-headline--medium {
    height: 60px;
  }
}

@media (max-width: 1600px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 250px;
  }
  .u-tileDropdown--margin {
    margin-top: 10px;
  }
}
@media (max-width: 1400px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 210px;
  }
  .tile--product-left img {
    max-height: 180px;
    max-width:100%;
    width: auto;
  }
  .tile--product-footer {
      bottom: -10px;
  }
  .tile--product {
  padding: 10px 10px 15px 10px;
  }
  .u-tileDropdown--margin {
    margin-top: 5px;
  }
  .tile-col--lower {
    font-size: @fontSize-small;
  }
  .u-tile--inputBottom {
    bottom: -15px;
  }
  .tileDropdown {
    bottom: 15px;
  }
}
@media (max-width: 1300px) {
  .tile--product__unavailable {
    left: 2em;
  }
}
@media (max-width: 1200px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 230px;
  }
  .u-tileDropdown--margin {
    margin-top: 25px;
  }
  .tile--product-footer {
    font-size: @fontSize-small;
  }
}
@media (max-width: 768px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 200px;
  }
}

// catalogue mobile styles

@media @media--tablet {
  .tileDropdown {
    bottom: 10px;
  }
  .u-price-fontSupplement {
    margin-top: 0;
  }
  #mobile-filter-modal {
    .mobile-filter-modal {
      max-width: 100%; 
      &.modal-dialog .modal-content {
        min-height: 100vh;
        height: auto !important;
      }
      .modal-content .shadowBox {
        display: block !important;
      }
    }
  }
  .col-lg-9 .mobile-quick-order.shadowBox {
    display: block !important;
  }
  .modal-content .brandFilter {
    display: flex;
  }
  .shadowBox:not(.accountDetails):not(.hoverSearch):not(.loginOverlay) {
    display: none !important;
  }
  #app .flatknittingTile .shadowBox {
    display: inline-block !important;
  }
  .catalogue-tiles-wrapper {
    // css grid two columns
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    > div {
      width: 100%;
    }
  }
  .tile-col--lower {
    hyphens: auto;
  }
  .mobile-quick-order .shadowBox-headline {
    margin: .3em 0;
  }
  .mobile-quick-order button {
    width: 100%;
    max-width: 50px;
  }
  .catalogue-wrapper {
    max-width: 90%;
    margin: auto;
  }
  .client-order-filter-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr .2fr;
    > * {
      margin-top: 20px !important;
    }
    > div {
      margin-left: 0;
    }
  }
  .tile--product-size-icon {
    float: none;
  }
}
@media @media--mobile {
  .client-order-filter-wrapper {
    grid-template-columns: 1fr .2fr;
    grid-template-rows: 1fr 1fr;
    > h3 {
      grid-column: 1;
      grid-row: 1;
    }
    > div {
      grid-column: 1;
      grid-row: 2;
      margin-top: 0 !important;
    }
    > button {
      grid-column: 2;
      grid-row: 1;
    }
  }
}
@media only screen and (max-width: 585px) {
  .catalogue-tiles-wrapper {
    // css grid one column
    display: grid;
    grid-template-columns: 1fr;
  }
  .product-modal {
    min-height: 100vh;
    height: auto;
  }
}

@media only screen and (max-width: 392px) {
  .client-order-filter-wrapper {
    .u-light {
      margin-left: 0 !important;
    }
  }
}