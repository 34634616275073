/*
 * Table styles
 */

@media (max-width: 767px) {
  .table-action--mobile {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
}

.table {
  border-collapse: separate;
  white-space: nowrap;
}
.table--clean {
  min-width: 100%;
  border-collapse: separate;
  white-space: nowrap;
}
.table-headline {
  font-family: @fontFamily-bold--table-headline;
  color: @color-brandBlack--table-headline;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: @borderColor-brandBlack--table-headline;
  border-top: 0 !important;
  padding: 10px 0 10px 20px;
}
.table-headline:first-child, .table-headline:last-child {
  padding-right: 10px;
}
.table-data {
  border-bottom-color: @color-brandBlack;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 20px 0 20px 20px;
  position: relative;
}
.table-data:first-child, .table-data:last-child {
  padding-right: 10px;
}
.parent .table-data {
  border-bottom: 0;
}
.table-data--slim {
  padding: 8px;
}
.table-row {
  border-bottom: 1px solid @color-brandBlack;
}
.table-row.even .table-data {
  background-color: @bg-brandWhite--table-data;
}
.table-row.odd .table-data {
  background-color: @bg-brandLighter--table-data;
}
.table-row:hover .table-data {
  background-color: @bg-brandLight--table-data;
}
.table-row:focus .table-data {
  background-color: inherit;
}
.table-data--action {
  text-align: right;
}
.table-row:hover {
  .table-action {
   color: @color-blueNeutral;
  }
}
.table-action,
.table-action:focus,
.table-action:link,
.table-action:active,
.table-action:visited {
  color: @color-blueNeutral;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.table-row--basket {
  height: 170px;
}
.table-row--basket .table-data:first-child {
  padding-left: 20px;
}
.table-head--basket .table-headline {
  border-bottom: 1px solid @color-brandBlack;
}
.table-row--basket .table-data {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid @color-brandBlack;
}

/*
 * Table Utilities
 */
.u-tableShrinkable { table-layout: fixed; }


/* DataTables CSS */
table.dataTable.dtr-column>tbody>tr>td.control:before,
table.dataTable.dtr-column>tbody>tr>th.control:before {
  font-family: 'ofa-icons';
  content: "\51";
  font-size: 20px;
  line-height: 20px;
  border: 0;
  .border-radius(0);
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: @color-brandDark;
}
table.dataTable.dtr-column>tbody>tr.parent td.control:before,
table.dataTable.dtr-column>tbody>tr.parent th.control:before {
  margin-left: -6px;
  background-color: transparent;
  content: "\51";
  transform: rotate(90deg);
  color: @bg-blueNeutral--button;
}
table.dataTable>tbody>tr.child ul{
  margin-left: 65px;
  margin-bottom: 10px;
}
table.dataTable>tbody>tr.child span.dtr-title {
  font-family: @fontFamily-bold;
  width: 165px;
}
table.dataTable>tbody>tr.child ul li {
  border-bottom:0;
}
table.dataTable>tbody>tr.child td {
  border-bottom: 1px solid @color-brandBlack;
}
.table-row.even + .child > td {
  background-color: @bg-brandWhite--table-data;
}
.table-row.odd + .child > td {
  background-color: @bg-brandLighter--table-data;
}
.table-row.even:hover + .child > td, .table-row.odd:hover + .child > td {
  background-color: @color-brandLight;
}


@media @media--tablet {
  .patient-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    > * {
      width: 90%;
    }
    .wrapper-grid-1 {
      grid-row: 1;
    }
    .wrapper-grid-2 {
      grid-row: 1;
      grid-column: 2 / span 3;
    }
    .grid-row-1 {
      grid-row: 1;
      grid-column: 1;
    }
    .grid-row-2 {
      grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .grid-row-3 {
      grid-row: 2;
      grid-column: 3;
      justify-self: end;
    }
    .grid-row-4 {
      grid-row: 2;
      grid-column: 1;
    }
    .grid-row-5 {
      grid-row: 3;
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .u-marginTop35--negative {
      margin-top: 0;
    }
    .u-marginTop30.text-right {
      text-align: left;
      margin-top: 0;
    }
    .u-marginTop30 {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 769px){
  .patient-list-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    .grid-row-1 {
      grid-row: 1;
      grid-column: unset;
    }
    .grid-row-2 {
      grid-row: 2;
      grid-column: unset;
    }
    .grid-row-3 {
      grid-row: 3;
      grid-column: unset;
    }
    .grid-row-4 {
      grid-row: 4;
      grid-column: unset;
    }
    .grid-row-5 {
      grid-row: 5;
      grid-column: unset;
    }
    > .col-sm-5 {
      width: auto;
    }
    .wrapper-grid-2 {
      grid-row: 2;
      grid-column: 1 / span 3;
    }
  }
}


@media only screen and (max-width: 360px) {
  .js-orderDetailTable {
    ul {
      margin-left: 10px !important;
    }
  }
}
@media @media--mobile {
  .js-clientTable {
    .dtr-title {
      max-width: 45%;
    }
  }
  .wishlist-product {
    white-space: normal;
    max-width: 50%;
  }
  .wishlist-order-actions {
    text-align: center;
  }
  .js-orderDetailTable {
    .dtr-title {
      max-width: 10%;
    }
    .dtr-data div {
      max-width: 50%;
      white-space: normal;
    }
    span.dtr-data {
      margin-right: -5em;
    }
  }
}
@media (max-width: 993px) {
  .patient-list-wrapper {
    .tabbed-view-wrapper.u-marginTop30 {
      margin-top: 0;
      margin-bottom: .2em;
    }
  }
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;

    a {
      flex: 1 0 auto;
      text-align: center;
      height: unset;
      line-height: unset;
      margin: 0.3em 0 0 0;
      width: 48%;

      &:first-child {
        width: 100%;
      }

      &:nth-child(2) {
        margin-right: 5px;
      }

      &:nth-child(3) {
        margin-left: 5px;
      }
    }

    form {
      width: 100%;
    }
  }
  }
