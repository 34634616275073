.productSpec-inputGroup {
  font-size: 14px;
}
.productSpec-inputGroup-input {
  display: inline-block;
  margin-bottom: 10px;
}
.productSpec-inputGroup .textLabel,
.productSpec-labelValue .textLabel {
  display: inline-block;
  font-size: @fontSize-large;
}
.productSpec-labelValue .value,
.productSpec-inputGroup .btn {
  display: inline-block;
}
.productSpec-information {
  text-transform: uppercase;
  cursor: pointer;
  margin: 20px 0;
}
.productSpec-information i {
  margin-right: 5px;
}
.productSpec-claim {
  font-size: @fontSize-large;
  width: 100%;
  margin: 5px 0;
}
.productSpec-textArea {
  width: 100%;
  height: 100px;
  border: 1px solid @color-brandNeutral;
  resize: none;
  padding: 5px 10px;
  margin-top: 5px;
}
.productSpec-textArea:focus {
  padding: 4px 9px;
}
.configurationLower {
  position: relative;
  opacity: 0.3;
}
.preventClickOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.productSpec-color--preview {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    .border-radius(50%);
    position: relative;
    margin-bottom: 0;
    pointer-events: none;
    display: block;
}
input:checked + .productSpec-color {
  border: 2px solid @color-brandNeutral;
}
.productSpec-color {
  vertical-align: top;
  padding: 3px;
  cursor: pointer;
  display: inline-block;
}
.productSpec-color--item {
  text-align: center;
  display: inline-block;
  width: 85px;
  margin-bottom: 10px;
  vertical-align: top;
}
.productSpec-sizeTableButton {
  color: @color-brandNeutral;
  border: 0;
  background-color: transparent;
}
.productSpec-sizeTableButtonContainer {
  margin-top: -15px;
  margin-left: 200px;
  margin-bottom: 10px;
}
.productSpec-sizeTableButton:focus {
  outline:0;
}
.productSpec-alternateTile {
  .shadowBox();
  width: 100%;
  height: 200px;
  padding: 10px 15px;
}
.productSpec-alternateLabel {
  font-family:@fontFamily-bold;
  display: block;
  font-size: 16px;
  color: @color-brandNeutral;
  margin-bottom: 10px;
}
//.productSpec-alternateTile.productSpec-alternateTile {
//  font-size: 12px;
//}
.productSpec-header-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  .pull-right {
    float: none !important;
  }
}
.productSpec-inputGroup.additionalInformation-mobile {
  display: none;
}
.product-amount {
  display: inline-block;
  span {
    display: block;
  }
  .product-amount-input-wrapper {
    display: flex;
    flex-direction: row;
    .inputGroup-textInput {
      margin: 0 .2em;
    }
    .product-amount-btn {
      background: none;
      border: none;
      padding: 0;
    }
  }
}
@media @media--tablet {
  .productSpec-inputGroup.additionalInformation-desktop {
    display: none;
  }
  .productSpec-inputGroup.additionalInformation-mobile {
    display: block
  }
  .productSpec-header-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    * {
      float: none;
    }
    .btn--primary {
      line-height: 2em;
    }
  }
}
@media @media--mobile {
  .productSpec-header-actions {
    .btn--primary {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
  }
}