.modal {
  text-align: center;
  z-index: 10100;
}
.modal-backdrop {
  z-index: 10000;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-content {
  .box-shadow(0, 0, 0, 0, transparent);
  border: 0;
  padding: 0 10px;
}
.modal-footer {
  border-top: 0;
  padding: 0;
}
.modal-header {
  border-bottom-color: @borderColor-brandNeutral--modal-header;
  border-bottom-width: 2px;
  margin-bottom: 15px;
}
.modal-header .close {
  margin-top: 14px;
}
.modal-header h3 {
  color: @color-brandNeutral--modal-header;
  margin-top: 10px;
}
.modal-lg {
  width: 1200px; /* Bootstraps default is 900px */
  max-width: 95%;
}
.modal-lg .modal-content {
  padding: 10px 15px;
  width: auto;
  height: auto;
}
.modal-lg .modal-header {
  padding: 0;
}
.modal-noBorder .modal-header {
  border: 0;
}
.modal-lg .modal-body {
  padding: 0;
}
.modal-lg .close {
  font-size: 2.4em;
  margin-top: 5px;
}
.modal-brand {
  margin: 20px 0 10px 0;
}
.modal-subheadline {
  font-size: @fontSize-larger;
}
.modal-brandText {
  margin-top: 30px;
  font-size: @fontSize-large;
  margin-bottom: 20px;
}
.modal-modalImage {
  float: right;
  margin-top: -130px;
  padding-left: 30px;
}
.modal-errors {
  display: none;
  font-family: @fontFamily-italic;
  color: @color-brandWarning;
  margin-top: 15px;
}
.variant {
  max-width: 200px;
  display: inline-block;
  margin-right: 50px;
}
.variantTitle {
  font-size: @fontSize-large;
  width: 100%;
  height: 2.7em;
  border-bottom: 1px solid @color-brandBlack;
  vertical-align: bottom;
  position: relative;
}
.variantTitle-content {
  position: absolute;
  padding-bottom: 5px;
  left: 0;
  bottom: 0;
}
.variant-image {
  max-height: 150px;
  max-width: 190px;
  margin-bottom: 5px;
}
.variant-additionalInformation {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  line-height: 1em;
  height: 2em;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid @color-brandBlack;
}
.variant-inputGroup {
  margin-top: 5px;
}
button.close,
button.close:hover,
button.close:focus {
  outline: 0;
  color: @color-brandNeutral--closeButton;
}
button.close {
  font-size: @fontSize-jumbo--closeButton;
  opacity:1;
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .modal-header h3 {
    margin-top: 20px;
  }
  .modal-lg .close {
    font-size: 2.4em;
    margin-top: 12px;
  }
  .modal-lg {
    max-width: 85%;
  }
}
@media @media--tablet-portrait {
  .modal-lg {
    max-width: none;
    width: 90%;
  }
  .modal-lg .modal-content {
    padding: 10px 40px 30px 40px;
  }
}
@media (max-height: 720px) {
  .modal-dialog {
    margin: 10px auto;
  }
}