/*
 * Input fields2
 *
 * This file contains all styles for inputs including text, number, radio, checkbox and dropdown.
 * It also contains all styles for labels.
 */

label {
  font-weight: normal; /* Override bootstraps label font-weight: bold; */
}

.inputGroup {
  display: block;
}
.inputGroup--inline {
  display: inline-block;
}
.inputGroup--sub {
  margin-top: -30px;
}
.inputGroup-headline {
  font-size: @fontSize-large--inputGroup-headline;
  color: @color-brandNeutral--inputGroup-headline;
  font-family: @fontFamily-bold--inputGroup-headline;
}
.inputGroup-numberInput,
.inputGroup-textInput,
.inputGroup-textArea {
  background-color: @bg-brandWhite--textInput;
  border-width: 1px;
  border-color: @color-brandNeutral;
  border-style: solid;
  color: @color-brandBlack--textInput;
  display: inline-block;
  font-family: @fontFamily-light--textInput;
  font-size: @fontSize-normal--textInput;
  padding: 3px 6px;
  margin: 18px 0; /* 18px = 20px - 1px (border) -1px (padding) */
  line-height: 16px;
}
.inputGroup-numberInput,
.inputGroup-textInput {
  height: 26px;
  width: 200px;
}
.inputGroup-textArea {
  height: 8em;
  width: 90%;
}
.u-patientForm {
  .inputGroup-textInput,
  .inputGroup-numberInput {
    width: 300px;
  }
}

.inputGroup-numberInput {
  width: auto;
}
.inputGroup--firstName-input {
  width: 166px !important; /* Patient Create View */
}
.inputGroup--birthday-input {
  width: 40px !important;
  margin-right: 0 !important;
} /* Patient Create View */
.inputGroup--birthyear-input {
  width: 106px !important;
  margin-right: 0 !important;
}
.inputGroup-textInput--noMargin,
.inputGroup-textInput--noMargin:focus {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}
.inputGroup-textInput--autoWidth {
  width: auto;
}
.inputGroup-textInput--newsletter {
  width:140px;
  height: 26px;
  vertical-align: top;
  border:0;
  margin: 0;
}
.inputGroup--postcode-textInput {
  margin-right: 0 !important;
  width: 130px !important; /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--city-textInput {
  margin-right: 0 !important;
  width: 166px !important; /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--street-textInput {
  margin-right: 0 !important;
  width: 206px !important; /* Patient Create View */
}
.inputGroup--housenumber-textInput {
  margin-right: 0 !important;
  width: 90px !important; /* Patient Create View */
}
.inputGroup--accountPostcode-textInput {
  margin-right: 0 !important;
  width: 105px !important; /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--accountCity-textInput {
  margin-right: 0 !important;
  width: 141px !important; /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
/* The following inputGroup fields are used in the basket for the custom delivery address on each line */
.inputGroup-basket--firstName,
.inputGroup-basket--lastName { width: 188px; }
.inputGroup-basket--postcode { width: 105px; }
.inputGroup-basket--city { width: 271px; }
.inputGroup-numberInput:focus,
.inputGroup-textInput:focus {
  margin-right: -2px;
  padding-left: 5px;
  padding-right: 5px;
}
.inputGroup-numberInput:focus,
.inputGroup-textInput:focus,
.inputGroup-textArea:focus {
  border-color: @color-blueNeutral;
  border-width: 2px;
  outline: 0;
}
.inputGroup-customAddressCheckbox:not(:checked) ~ .inputGroup-customAddressContainer {
  display: none !important;
}
.inputGroup-customAddressCheckbox:checked ~ .inputGroup-customAddressContainer {
  display: block;
}
.inputGroup-textArea:focus {
  padding: 2px 5px;
}
.text-right {
  .inputGroup-numberInput:focus,
  .inputGroup-textInput:focus {
    margin-left: -2px;
    margin-right: 0;
  }
}
.inputGroup-customAddressCheckbox:not(:checked) ~ .u-inlineBlock .select2 {
  opacity: 0.5;
}
.inputGroup-customAddressCheckbox:checked ~ .u-inlineBlock .select2 {
  opacity: 1;
}
.inputGroup-textInput.is-faulty,
.inputGroup-numberInput.is-faulty {
  color: @color-brandWarning--textInput;
  border-color: @borderColor-brandWarning--textInput;
}
.inputGroup-textLabel {
  color: @color-brandBlack;
  display: inline-block;
  width: 200px; /* IMPORTANT there is a dependency to keyValue-key in the layout!!! */
  font-size: @fontSize-basel--textLabel;
  font-family: @fontFamily-light--textLabel;
  line-height: 20px;
  vertical-align: top;
  margin: 20px 0;
  box-sizing: border-box;
}
.inputGroup-hint {
  font-family: @fontFamily-italic--hint;
}
.inputGroup-input {
  margin: 20px 0;
  display: inline-block;
}
.inputGroup-checkBoxLabel { margin-right: 10px; }
.inputGroup-checkBoxLabel:last-of-type { margin-right: 0px; }
.inputGroup-checkBoxLabel:before {
  content: '';
  background-image: url(../../images/input-sprites.png);
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  margin-right: 3px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.inputGroup-checkBoxInput + .inputGroup-checkBoxLabel:before {
  background-position: -5px -101px;
}
.inputGroup-checkBoxInput:checked + .inputGroup-checkBoxLabel:before {
  background-position: -5px -5px;
}
.inputGroup-checkBoxInput { display: none; }
.inputGroup-radioLabel {
  margin-right: 30px;
  margin-bottom: 16px;
}
.inputGroup-radioLabel div { // currently only for shipment options
  font-family: @fontFamily-italic;
}
.inputGroup-radioLabel:last-of-type { margin-right: 0; }
.inputGroup-radioLabel:before {
  content: '';
  background-image: url(../../images/input-sprites.png);
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-bottom: -3px;
  margin-right: 5px;
  background-repeat: no-repeat;
}
.inputGroup-radioInput + .inputGroup-radioLabel:before {
  background-position: -4px -218px;
}
.inputGroup-radioInput:checked + .inputGroup-radioLabel:before {
  background-position: -4px -192px;
}
.inputGroup-radioInput {
  display: none;
  pointer-events: none;
}
.inputGroup-radioInput:disabled + .inputGroup-radioLabel,
.inputGroup-radioInput:not(:checked) ~ div > .subChoiceLabel {
  opacity: 0.5;
}
.inputGroup-selectInput {
  .border-radius(0);
  border-width:2px;
  border-color: @borderColor-brandLight--selectInput;
  background-color: transparent;
  padding: 5px 35px 5px 5px;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../images/input-sprites.png);
  background-repeat: no-repeat;
  background-position: right -5px top -151px;
  background-size: 40px;
}
.inputGroup-sizeLabel,
.inputGroup-radioLabel,
.inputGroup-variationLabel,
.inputGroup-gripTopLabel{
  cursor: pointer;
  display: inline-block;
}
.inputGroup-sizeLabel {
  display: inline-block;
  height: 35px;
  min-width: 35px;
  .border-radius(17px);
  border-width: 2px;
  line-height: 31px;
  font-size: 16px;
  border-style: solid;
  text-align: center;
  margin-right: 10px;
  border-color: @color-brandDark;
  background-color: @color-brandWhite;
  color: @color-brandDark;
  padding: 0 7px;
}
.inputGroup-radioInput:checked + .inputGroup-sizeLabel {
  background-color: @color-brandDark;
  color: @color-brandWhite;
}
.inputGroup-errors {
  color: @color-brandWarning--inputGroup-errors;
  font-family: @fontFamily-italic--inputGroup-errors;
  margin-top: -15px;
  margin-left: 205px;
  padding: 0;
  list-style: none;
}
.inputGroup--noInline-errors {
  margin-left: 0;
}
.inputGroup-radioLabel img,
.inputGroup-variationLabel img,
.inputGroup-gripTopLabel img {
  pointer-events: none;
}
.inputGroup-variationLabel,
.inputGroup-gripTopLabel{
  text-align: center;
  border: 2px solid @color-brandWhite;
  padding: 4px 10px;
  vertical-align: top;
}
.inputGroup-gripTopLabel {
  width: 120px;
  display: inline-block;
}
.inputGroup-gripTopLabel img {
  max-width: 100px;
  height:70px;
}
.inputGroup-radioInput:checked + .inputGroup-variationLabel,
.inputGroup-radioInput:checked + .inputGroup-gripTopLabel
{
  border: 2px solid @color-brandNeutral;
}
.inputGroup-additionalLabel {
  font-family: @fontFamily-italic;
  color: @color-brandNeutral;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 20px;
  margin-left: -40px;
}
/*
 * Sometimes label and input are not inline
 * Use inputGroup--noInline-textInput for that.
 */
.inputGroup-textInput.u-noInline {
  display: block;
  margin: 5px 0;
}
.inputGroup-textLabel.u-noInline {
  display: block;
  margin-bottom: 0;
}
.inputGroup-errors.u-noInline {
  margin-left:0;
  margin-top: 5px;
}

/*
 * Button for input-files
 * Checkout the button in the image_field.html as an example for html + JS(!).
 */
.inputfile-button {
  width: 0.1px;
  height: 0.1px;
  visibility: hidden;
  overflow: hidden;
}
.inputfile-button + label {
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}
.textInput--supplementProduct {
  width: 50px;
}
.inputGroup-amountInput {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
.inputGroup-helptext {
  margin-top: -17px;
}
/*
 * Some input specific utilities
 */
.inputGroup-checkBoxInput + label + .inputGroup-reason {
  display: none;
}
.inputGroup-checkBoxInput:checked + label + .inputGroup-reason {
  display: block;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}
input[type=number]{
  -moz-appearance: textfield;
}
input[type="text"]:disabled,
input[type="number"]:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}
/*
 * PLACEHOLDER
 */
::-webkit-input-placeholder { 
  color: @color-brandNeutral--placeholder;
  font-family: @fontFamily-italic--placeholder;
}
::-moz-placeholder { 
  color: @color-brandNeutral--placeholder;
  font-family: @fontFamily-italic--placeholder;
}
:-ms-input-placeholder { 
  color: @color-brandNeutral--placeholder;
  font-family: @fontFamily-italic--placeholder;
}
input:-moz-placeholder { 
  color: @color-brandNeutral--placeholder;
  font-family: @fontFamily-italic--placeholder;
}
@media (max-width: 1400px) {
  .inputGroup-gripTopLabel {
    width: 110px;

  }

  .inputGroup-gripTopLabel img {
    max-width: 80px;
    height: 50px;
  }
}
@media (max-width: 1280px) {
  .input-search {
    width: 120px;
  }
  .inputGroup-textInput--newsletter {
    width: 125px;
  }
}
.productionNumberInput-input {
  font-size: 18px;
  width: 140px !important;
  height: 25px !important;
}