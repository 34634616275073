html, body {
    height: 100%;
}
strong {
    font-family:@fontFamily-bold;
}
a:hover, a:focus {
    color: inherit;
}
.container-fluid {
    min-height:100%;
}
.col-xs-15 {
    width: 20%;
    float: left;
}
.col-xs-25 {
    width: 40%;
    float: left;
}
.text-left + .tooltip > .tooltip-inner {
    text-align: left;
}
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.row-eq-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
.row-eq-height img {
    max-width: 100%;
    align-self: baseline;
}
.row-eq-height .productSpec-alternateTile {
    height: 100%;
}

@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
.col-sm-25 {
    width: 40%;
    float: left;
}
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
    .col-md-25 {
        width: 40%;
        float: left;
    }
    .col-lg-17 {
        width: 100%;
        float: left;
    }
    .col-lg-37 {
        width: 100%;
        float: left;
    }
    .col-lg-47 {
        width: 100%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
    .col-lg-25 {
        width: 40%;
        float: left;
    }
    .col-lg-17 {
        width: 33.3333333333%;
        float: left;
    }
    .col-md-17 {
        width: 100%;
        float: left;
    }
}

@media (max-width: 1150px) {
    body {
        padding-top: 110px;
    }
}


@media (min-width: 1440px) {
    .col-xl-2 {
        width: 16.66666666%;
        float: left;
    }
    .col-xl-10 {
        width: 83.33333333%;
        float: left;
    }
    .col-xl-8 {
        width: 66.66666666%;
        float: left;
    }
    .col-xl-6 {
        width: 50%;
        float: left;
    }
    .col-xl-offset-6 {
        margin-left: 50%;
    }
    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xl-offset-2 {
        margin-left: 16.6666667%;
    }
}
@media (min-width: 1600px) {
    .col-lg-17 {
        width: 14.2857142857%;
        float: left;
    }
    .col-lg-37 {
        width: 42.8571428571%;
        float: left;
    }
    .col-lg-47 {
        width: 57.1428571429%;
        float: left;
    }
    .col-xl-4 {
        width: 33.3333333333%;
        float: left;
    }
}
ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
}
@page {
    size: A4;
}
@media print {
    nav {
        display: none;
    }
    .footer {
        display: none;
    }
    .breadcrumbs {
        display: none;
    }
    img {
        display: none;
    }
    .pagebreakBefore {
        page-break-before: always;
    }
    .select2 {
        display: none;
    }
    .inputGroup-radioInput, .inputGroup-checkBoxInput {
        display: inline-block !important;
        margin: 0;
    }
    .inputGroup-radioLabel:before, .inputGroup-checkBoxLabel:before {
        display: none;
    }
}

/* col-mdlg is used for the menu, to trigger custom screen-widths at which the menu is displayed in a full row */
@media (min-width: 1010px) {
    .col-mdlg-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-mdlg-10 {
        width: 83.33333333%;
        //position: relative;
        //min-height: 1px;
        //padding-left: 10px;
        //padding-right: 10px;
    }
}